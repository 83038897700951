import React, { useEffect, useState } from 'react'
import ClientDropDown from '../../Components/ClientDropDown'
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, Select, Table, TextField } from '@mui/material'
import { units } from '../../Utils/Units'
import SaveIcon from '@mui/icons-material/Save';
import { MdRemoveCircleOutline } from 'react-icons/md'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { instance } from '../../Const/ApiHeader';
import { GET_ALL_ITEMS, GET_SINGLE_ITEM, NEW_GST_INVOICE } from '../../Const/ApiConst';
import { Link, useNavigate } from 'react-router-dom';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { LoadingButton } from '@mui/lab';


function NewBilling() {
    const Navigate = useNavigate()
    const [clientId, setClientId] = useState(JSON.parse(sessionStorage.getItem('gstBillCustData')) || [])
    const [location, setLocation] = useState()
    const [noOfDays, setNoOfDays] = useState()
    const [invoiceDate, setInvoiceDate] = useState(new Date())
    const [item, setItem] = useState()
    const [unit, setUnit] = useState("Nos")
    const [hsn, setHSN] = useState()
    const [tax, setTax] = useState()
    const [rate, setRate] = useState()
    const [qty, setQty] = useState()
    const [tempArray, setTempArray] = useState(JSON.parse(sessionStorage.getItem('gstBillItems')) || [])
    const [narration, setNarration] = useState()
    const [iitteemm, setiitteemm] = useState([])
    const [grandTotal, setGrandtotal] = useState(0)
    const [transportingChrage, setTransportingCharge] = useState(0)
    const [discount, setDiscount] = useState(0)
    var taxvalue = 0;
    var taxsum = 0;
    const [saveLoading, setSaveLoading] = useState(false)

    const [EvtFromDate, setEvtFromDate] = useState()
    const [EvtToDate, setEvtToDate] = useState()

    const loadItemList = async () => {
        try {
            await instance.get(
                GET_ALL_ITEMS
            ).then(itemResponse => {
                setiitteemm(itemResponse.data.data.map((item) => {
                    console.log(iitteemm);
                    return { id: item._id, value: item.item_name }
                }))
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        loadItemList()
        // eslint-disable-next-line
    }, [])

    var a = 0;
    var sum = 0;
    var lineTotal = 0;

    const [open, setOpen] = useState(false);
    const [validationModalError, setValidationModalError] = useState('');

    const handleOpen = (msg) => {
        setValidationModalError(msg)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const unitChange = (e) => {
        setUnit(e.target.value)
    }


    const eventEndDateAction = (e) => {
        setEvtToDate(e.target.value)
        // Convert dates to milliseconds
        const date1InMs = new Date(EvtFromDate).getTime();
        const date2InMs = new Date(e.target.value).getTime();

        // Calculate the difference in milliseconds
        const diffInMs = Math.abs(date2InMs - date1InMs);

        // Convert milliseconds to days
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        setNoOfDays(diffInDays)
    }

    const saveToTempArray = () => {

        if (EvtFromDate === undefined || EvtFromDate === '') {
            handleOpen("Please enter event Date")
        } else if (noOfDays === undefined || noOfDays === '') {
            handleOpen("Please enter number of days")
        } else if (item === undefined || item === '') {
            handleOpen('Please select an Item')
        } else if (qty === undefined || qty === '') {
            handleOpen('Please enter Quatity')
        } else if (rate === undefined || rate === '') {
            handleOpen('Please enter Rate')
        } else {

            const data = {
                'item_name': item,
                'qty': qty,
                "unit": unit,
                "hsn": hsn,
                "tax": tax,
                "rate": rate,
                'noOfDays': noOfDays,
            }
            const newTempArray = [...tempArray, data]

            setTempArray(newTempArray)

            sessionStorage.setItem("gstBillItems", JSON.stringify(newTempArray))
            sessionStorage.setItem("gstBillCustData", JSON.stringify(clientId))

        }
    }


    const removeItemTempArray = (i) => {
        const removedTempItemsArray = tempArray.filter((res, e) => e !== i)
        setTempArray(removedTempItemsArray)
        sessionStorage.removeItem("gstBillItems")
        sessionStorage.setItem("gstBillItems", JSON.stringify(removedTempItemsArray))
        sessionStorage.setItem("gstBillCustData", JSON.stringify(clientId))
    }

    const saveAction = async () => {
        setSaveLoading(true)
        if (tempArray.length <= 0) {
            handleOpen("No data! Please add items")
            setSaveLoading(false)
        } else if (clientId.length <= 0) {
            handleOpen("Please select Customer")
            setSaveLoading(false)
        } else if (location === undefined || location === '') {
            handleOpen("Please enter Location")
            setSaveLoading(false)
        } else {
            var cust_id = '';

            clientId.forEach(res => {
                cust_id = res._id;
            });

            const data = {
                cust_id: cust_id,
                location: location,
                no_of_days: noOfDays,
                narration: narration,
                sum: sum,
                invoiceType: 'gst_bill',
                invoiceData: tempArray,
                invoiceTotal: sum,
                transportingChrage: transportingChrage,
                discount: discount,
                EvtFromDate: EvtFromDate,
                invoice_date: invoiceDate
            }
            try {
                await instance.post(
                    NEW_GST_INVOICE,
                    data
                ).then(res => {
                    Navigate('/View_Invoice/' + res.data.data._id)
                    sessionStorage.removeItem("gstBillCustData")
                    sessionStorage.removeItem("gstBillItems")
                    setSaveLoading(false)
                }).catch(err => {
                    console.log(err);
                    setSaveLoading(false)
                })
            } catch (error) {
                console.log(error);
                setSaveLoading(false)
            }
        }
    }

    const [tempItem, setTempItem] = useState()

    const getItemdetailsAction = async (itm) => {
        setTempItem(itm.target.value)
        try {
            const data = {
                id: itm.target.value
            }
            await instance.post(
                GET_SINGLE_ITEM,
                data
            ).then(response => {
                console.log(response);
                setItem(response.data.data[0].item_name);
                setHSN(response.data.data[0].hsn);
                setTax(response.data.data[0].tax);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const adjustAction = (e) => {
        setDiscount(e.target.value)
        setGrandtotal(parseFloat(sum) - parseFloat(e.target.value))
    }

    const transportingCharageAction = (e) => {
        if (e.target.value === '') {
            setGrandtotal(parseFloat(sum) + 0)
            setTransportingCharge(e.target.value)
        } else {
            setGrandtotal(parseFloat(sum) + parseFloat(e.target.value))
            setTransportingCharge(e.target.value)
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 2,
    };

    useEffect(() => {
        setClientId(clientId)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className='page-title'>New Billing</div>
            <div style={{ padding: '10px' }}>
                <Link to="/Billing">
                    <Button variant='contained' color='success'>Back</Button>
                </Link>
            </div>
            <main className='d-flex gap-3 flex-wrap'>
                <div className='estimate-item-container w-100'>
                    <div className='searchbillingclient w-100'>
                        <div className='searchFilterDropdown w-100' >
                            <div style={{ width: '165px', marginTop: '10px' }}>Search Client</div>
                            <div className='d-flex flex-column w-100'>
                                <ClientDropDown sendToParent={setClientId} />
                            </div>
                        </div>
                    </div>
                    <div className='w-100 ddd'>
                        <TextField
                            size='small'
                            label="No of Days"
                            className='w-100'
                            onChange={(e) => setNoOfDays(e.target.value)}
                        />
                    </div>
                    <div className='w-100 ddd'>
                        <TextField
                            type='date'
                            value={invoiceDate.toISOString().slice(0, 10)} // Convert Date to string in YYYY-MM-DD format
                            size='small'
                            label="Invoice Date"
                            className='w-100'
                            onChange={(e) => { setInvoiceDate(new Date(e.target.value)) }} // Convert the input string back to a Date object
                        />
                    </div>
                    <div className='w-100 ddd'>
                        <TextField
                            size='small'
                            label="Event Date"
                            className='w-100'
                            onChange={(e) => setEvtFromDate(e.target.value)} />
                    </div>

                    <div className='w-100'>
                        <TextField
                            size='small'
                            label='Location'
                            className='w-100'
                            onChange={(e) => setLocation(e.target.value)} />
                    </div>
                </div>
                <hr style={{ margin: '0 0', width: '100%' }} />
                <strong>Client Details</strong>
                {clientId.map((res, i) => (
                    <div key={i} className="estimate-item-container w-100">
                        <TextField
                            size='small'
                            value={res.pointOfContact}
                            label="Contact Person"
                            className='w-100'
                            disabled />
                        <TextField
                            size='small'
                            value={res.ContactNo}
                            label="Contact No"
                            className='w-100'
                            disabled />
                        <TextField
                            size='small'
                            value={res.PartnerName}
                            label="Company Name"
                            className='w-100'
                            disabled />
                        <TextField
                            size='small'
                            value={res.GSTNo}
                            label="GST No"
                            className='w-100'
                            disabled />
                        <TextField
                            size='small'
                            value={res.Address}
                            label="Address"
                            className='w-100'
                            disabled />
                    </div>
                ))}
            </main>
            <main>
                <strong>Items</strong>
                <div className='estimate-item-container mt-3 mb-1'>
                    <div className='w-100'>
                        <FormControl className='w-100'>
                            <InputLabel size='small' id="gsttItems">Items</InputLabel>
                            <Select
                                labelId="gsttItems"
                                size='small'
                                id="gsttItems"
                                label="Items"
                                value={tempItem}
                                onChange={(e) => getItemdetailsAction(e)}
                            >
                                {iitteemm.map((res, i) => (
                                    <MenuItem value={res.id} key={i}>{res.value}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <div className='w-100'>
                        <FormControl className='w-100'>
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                size='small'
                                id="demo-simple-select"
                                label="Unit"
                                defaultValue={"Nos"}
                                className='w-100'
                                onChange={(e) => unitChange(e)}
                            >
                                {units.map((res, i) => (
                                    <MenuItem key={i} value={res}>{res}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='w-100'>
                        <TextField
                            size='small'
                            label="Item Qty"
                            type='number'
                            className='w-100'
                            onChange={(e) => setQty(e.target.value)} />
                    </div>
                    <div className='w-100'>
                        <TextField
                            size='small'
                            label="Item Rate"
                            className='w-100'
                            type='number'
                            onChange={(e) => setRate(e.target.value)} />
                    </div>

                    <Button variant='contained' onClick={() => saveToTempArray()}>Add</Button>
                </div>
            </main>
            <main style={{ overflow: 'auto' }}>
                <strong>Preview</strong>
                <Table>
                    <thead>
                        <tr>
                            <th className='text-center'>Sl No</th>
                            <th className='text-center'>Particulars</th>
                            <th className='text-center'>HSN</th>
                            <th className='text-center'>Unit</th>
                            <th className='text-center'>Qty</th>
                            <th className='text-center'>No of Days</th>
                            <th className='text-center'>Rate</th>
                            <th className='text-center'>Taxable Value</th>
                            <th className='text-center'>Tax%</th>
                            <th className='text-center'>Tax</th>
                            <th style={{ width: '200px' }} className='text-center'  >Total</th>
                            <th className='text-center'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempArray.map((res, i) => (
                            <tr key={i}>
                                <td className='text-center'>{a = a + 1}</td>
                                <td>{res.item_name}</td>
                                <td className='text-center'>{res.hsn}</td>
                                <td className='text-center'>{res.unit}</td>
                                <td className='text-center'>{res.qty}</td>
                                <td className='text-center'>{res.noOfDays}</td>
                                <td className='text-end'>₹ {res.rate}</td>
                                <td className='text-end'> ₹ {taxvalue = res.qty * res.rate}</td>
                                <td className='text-center'>{res.tax}</td>
                                <td className='text-end pe-2'>₹ {taxvalue * res.tax / 100} <div style={{ display: 'none' }}>{taxsum = taxsum + (taxvalue * res.tax / 100)}</div></td>
                                <th style={{ width: '120px' }} className='text-end pe-2'>₹ {lineTotal = taxvalue + taxvalue * res.tax / 100}</th>
                                <th><MdRemoveCircleOutline className='text-danger' style={{ fontSize: '24px' }} onClick={() => removeItemTempArray(i)} /><div style={{ display: 'none' }}>{sum = sum + parseFloat(lineTotal)}</div></th>

                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr style={{ borderTop: '1px solid black', }}>
                            <th colSpan={9}></th>
                            <th className='text-end'>Total</th>
                            <th className='text-end p-2'>
                                <TextField
                                    style={{ width: '120px' }}
                                    size='small'
                                    label="Total"
                                    value={sum}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    }}
                                    disabled /></th>
                            <th></th>
                        </tr>
                        {/* <tr>
                            <td colSpan={10} className='text-end'>Transporting Charge</td>
                            <td className='text-end p-2'>
                                <TextField
                                    style={{ width: '120px' }}
                                    size='small'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    }}
                                    onChange={(e) => transportingCharageAction(e)}
                                /></td>
                        </tr> */}
                        <tr>
                            <td colSpan={10} className='text-end'>Discount</td>
                            <td className='text-end p-2'>
                                <TextField
                                    style={{ width: '120px' }}
                                    size='small'
                                    label="less"
                                    defaultValue={discount}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    }}
                                    onChange={(e) => adjustAction(e)}
                                /></td>
                        </tr>

                        <tr>
                            <th colSpan={10} className='text-end'>Grand Total</th>
                            <td className='text-end p-2'>
                                <TextField
                                    style={{ width: '120px' }}
                                    size='small'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                    }}
                                    value={sum - discount} disabled /></td>
                        </tr>
                    </tfoot>
                </Table>
                <div className='mb-4 mt-2'>
                    <TextField label="Narration" rows={5} multiline fullWidth onChange={(e) => setNarration(e.target.value)} />
                </div>
                <LoadingButton
                    loading={saveLoading}
                    variant='contained'
                    startIcon={<SaveIcon />}
                    endIcon={<LocalPrintshopIcon />}
                    onClick={() => { saveAction() }}
                    className="d-flex align-items-center gap-2">
                    Save & Print
                </LoadingButton>
            </main>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='text-danger'>
                    <DangerousIcon className='text-danger me-2' />
                    <strong>Error : </strong>
                    {validationModalError}
                </Box>
            </Modal>
        </>
    )
}

export default NewBilling