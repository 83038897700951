import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GET_VENDOR } from '../../Const/ApiConst'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';
import { Form } from 'react-bootstrap'

const partnerType = "Client"
const api_key = process.env.REACT_APP_API_KEY
const token = sessionStorage.getItem('wsstfaarvav')

const instance = axios.create({
    headers: {
        "Content-Type": 'application/json',
        'x-auth-token': token,
        'x-api-key': api_key,
        'PartnerType': partnerType
    }
})

function Clients() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            name: 'Company Name',
            selector: row => row.PartnerName,
        },
        {
            name: 'Contact No',
            selector: row => row.ContactNo,
        },
        {
            name: 'Email ID',
            selector: row => row.Email,
        },
        {
            name: 'Address',
            selector: row => row.Address,
        },
        {
            name: 'Pin Code',
            selector: row => row.PinCode,
        },
        {
            name: 'GST No',
            selector: row => row.GSTNo,
        },
        {
            name: '',
            selector: row => <Link to={'/View_Client/' + row._id}><Button size='small' variant='contained' color='warning'>View</Button></Link>
        },

    ];


    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        const response = await instance.post(
            GET_VENDOR,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };



    const handlePageChange = page => {
        fetchUsers(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_VENDOR,
            data
        )

        console.log(response.data.data);
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };


    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchAction = async (e, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage,
            searchKey: e.target.value
        }
        const response = await instance.post(
            GET_VENDOR,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }

    return (
        <>
            <div className='page-title'>Clients</div>
            <div style={{ padding: '10px' }}><Link to='/Add_Client'><Button variant='contained' color='success' startIcon={<AddIcon />}>Add Client</Button></Link></div>
            <main>
                <div className='col-12 col-sm-3'>
                    <Form.Control
                        type="search"
                        className='mb-2'
                        placeholder='Search...'
                        onChange={(e) => searchAction(e)} />
                </div>

                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />


            </main>
        </>
    )
}

export default Clients