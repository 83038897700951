import React from 'react'
import CountBoxs from './CountBoxs'
import Last5Expense from './Last5Expense'
import WelcomeMessage from './WelcomeMessage'

function Dashboard() {


  return (
    <>
      <WelcomeMessage />
      <CountBoxs />
      <Last5Expense />
    </>
  )
}

export default Dashboard