import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { instance } from '../../Const/ApiHeader'
import { GET_EXPENSE_REPORT } from '../../Const/ApiConst'
import moment from 'moment'
import { VARIABLE_EXPENSE_TYPES } from '../../Variables/ExpenseList'
import { utils, writeFile } from 'xlsx'

function ExpenseComponent() {
    const [expenseData, setExpenseData] = useState([])
    const [expType, setExptype] = useState('All')
    const [fromDate, setFromDate] = useState()
    const [toDate, setTodate] = useState()
    const [estimateExcelData, setestimateExcelData] = useState([])

    const loadData = async () => {
        var xdata = []
        if (fromDate === undefined || toDate === undefined) {

        } else {
            try {
                const data = {
                    fromDate: fromDate,
                    toDate: toDate,
                    expenseType: expType
                }
                await instance.post(
                    GET_EXPENSE_REPORT,
                    data
                ).then(res => {
                    setExpenseData(res.data.data)
                    for (let index = 0; index < res.data.data.length; index++) {
                        console.log(res.data.data.length);
                        xdata.push({
                            invoice_date: moment(res.data.data[index].created_at).format("DD/MM/YYYY"),
                            expname: res.data.data[index].expense_name,
                            exptype: res.data.data[index].expense_type,
                            expreference: res.data.data[index].reference,
                            expamout: res.data.data[index].amount,
                        })
                    }
                    console.log(xdata);
                    setestimateExcelData(xdata)
                }).catch(err => console.log(err))

            } catch (error) {
                console.log(error);
            }
        }
    }


    const tableData = () => {
        return expenseData.map((res, i) => {
            console.log(res);
            return <tr key={i}>
                <td>{moment(res.created_at).format("DD/MM/YYYY")}</td>
                <td>{res.expense_name}</td>
                <td>{res.expense_type}</td>
                <td>{res.reference}</td>
                <td className='text-end'>{res.amount.toFixed(2)}</td>
            </tr>
        })
    }

    const handleExport = () => {
        console.log(estimateExcelData);
        const headings = [[
            'Date',
            'Expense Name',
            'Expense Type',
            'Reference',
            'Amount',

        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, estimateExcelData, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Expense Report.xlsx');
    }


    return (
        <div>
            <div className='d-flex gap-3'>
                <div>
                    <div>Expense Type</div>
                    <Form.Select type='date' onChange={(e) => setExptype(e.target.value)}>
                        <option>All</option>
                        {VARIABLE_EXPENSE_TYPES.map((expTypes, i) => (
                            <option key={i}>{expTypes}</option>
                        ))}
                    </Form.Select>
                </div>
                <div>
                    <div>From Date</div>
                    <Form.Control type='date' onChange={(e) => setFromDate(e.target.value)} />
                </div>
                <div>
                    <div>To Date</div>
                    <Form.Control type='date' onChange={(e) => setTodate(e.target.value)} />
                </div>
                <div>
                    <div>&nbsp;</div>
                    <Button variant='contained' onClick={() => loadData()}>Search</Button>
                </div>
            </div>

            <div className='mt-2'>
                <div className='d-flex justify-content-end'>
                    <Button variant='contained' onClick={() => handleExport()}>Download</Button>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Expense Name</th>
                            <th>Expense Type</th>
                            <th>Reference</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData()}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ExpenseComponent