import React from 'react'
import welcomeimage from '../../images/welcome_image.svg'
import { Button } from '@mui/material'

function WelcomeMessage() {
    return (
        <div>
            <div className='dash-intro-container'>
                <div>
                    <h4><strong className='text-success'>Welcome back!</strong></h4>
                    <h4><strong className='text-success'>Event Magagment System</strong></h4>
                    <p>Welcome to our app! We're excited to have you join us and hope you enjoy your experience.</p>
                    {/* <p>Welcome to our app! We're thrilled to have you on board and can't wait to show you all the amazing features we've created for you. Get ready for a seamless and enjoyable user experience.</p> */}
                    <Button variant='contained' color='success'>Contact Us</Button>
                </div>
                <img src={welcomeimage} alt='' style={{ width: '280px', margin: '10px' }} />
            </div>
        </div>
    )
}

export default WelcomeMessage