import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ADD_PARTNER, GET_SINGLE_PARTNER, GET_PARTNER_LIST } from '../Const/ApiConst'
import '../App.css'
import { TextField } from '@mui/material'



const token = sessionStorage.getItem('wsstfaarvav')
const api_key = process.env.REACT_APP_API_KEY
const partnerType = "Client"
const instance = axios.create({
    headers: {
        "Content-Type": 'application/json',
        'x-auth-token': token,
        'x-api-key': api_key,
        'PartnerType': partnerType
    }
})

function ClientDropDown(props) {

    const [allClients, setAllClients] = useState([])
    const dropdownref = useRef()
    const [filteredData, setFilteredData] = useState('')
    const getClientData = async () => {
        try {
            await instance.get(
                GET_PARTNER_LIST
            ).then(res => {
                setAllClients(res.data.data)
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (e) => {
        setFilteredData(e.target.value)
        dropdownref.current.classList.remove("hidden")
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownref.current && !dropdownref.current.contains(event.target)) {
                dropdownref.current.className += " hidden"
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    })

    useEffect(() => {
        getClientData()
    }, [])

    const setValue = (e) => {
        // props.sendToParent(e.target.value)
        setFilteredData(e.target.innerText)
        findCustomer(e)
        dropdownref.current.className += " hidden"
    }


    const findCustomer = async (e) => {

        try {
            const data = {
                id: e.target.value
            }
            await instance.post(
                GET_SINGLE_PARTNER,
                data
            ).then(res => {
                props.sendToParent(res.data.data)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const filterByData = () => {

        return allClients.filter((allClients) => allClients.PartnerName.toLowerCase().includes(filteredData))
            .map((res, i) => {
                return <button className='btn w-100' value={res._id} key={i} onClick={(e) => setValue(e)}>{res.PartnerName}</button>
            })
    }
    const [modalShow, setModalShow] = React.useState(false);


    function MyVerticallyCenteredModal(props) {

        const [name, setName] = useState()
        const [contact, setContact] = useState()
        const [email, setEmail] = useState()
        const [gst, setGst] = useState()
        const [state, setState] = useState()
        const [stateCode, setStateCode] = useState()
        const [pointOfContact, setPOintOfConact] = useState()
        const [address, setAddress] = useState()
        const [Pincode, setPincode] = useState()

        const saveForm = async (e) => {
            e.preventDefault()
            const data = {
                name: name,
                contact: contact,
                email: email,
                gst: gst,
                state: state,
                stateCode: stateCode,
                pointOfContact: pointOfContact,
                address: address,
                Pincode: Pincode,
                PartnerType: "Client"
            }

            try {
                await instance.post(
                    ADD_PARTNER,
                    data
                ).then(res => {
                    console.log(res.data.data);
                    setModalShow(false)
                    getClientData()
                }).catch(err => {
                    console.log(err);
                })
            } catch (error) {
                console.log(error);
            }

        }

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Form onSubmit={(e) => saveForm(e)} autoComplete='off'>
                        <Form.Group className='form-row'>
                            <Form.Group className='form-col'>
                                <strong>Basic Details</strong>
                            </Form.Group>
                            <Form.Group className='form-col'>
                                <TextField size="small" required label="Client Name" onChange={(e) => setName(e.target.value)} />
                                <TextField size="small" required label="Contact No" onChange={(e) => setContact(e.target.value)} />
                                <TextField size="small" label="Email Address" onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                        </Form.Group>
                        <hr />
                        <Form.Group className='form-row'>
                            <Form.Group className='form-col'>
                                <strong>GST</strong>
                            </Form.Group>
                            <Form.Group className='form-col'>
                                <TextField size="small" label="GST No" onChange={(e) => setGst(e.target.value)} />
                                <TextField size="small" label="State" onChange={(e) => setState(e.target.value)} />
                                <TextField size="small" label="State Code" onChange={(e) => setStateCode(e.target.value)} />
                            </Form.Group>
                        </Form.Group>
                        <hr />
                        <Form.Group className='form-row'>
                            <Form.Group className='form-col'>
                                <strong>Contact</strong>
                            </Form.Group>
                            <Form.Group className='form-col'>
                                <TextField size="small" required label="Point of Contact" onChange={(e) => setPOintOfConact(e.target.value)} />
                                <TextField size="small" label="Address" onChange={(e) => setAddress(e.target.value)} />
                                <TextField size="small" label="Pin Code" onChange={(e) => setPincode(e.target.value)} />
                            </Form.Group>
                        </Form.Group>
                        <Form.Group className='form-row'>
                            <div className='form-col'></div>
                            <div className='form-col mt-4'>
                                <Button variant='primary' type='submit'  >Save</Button>
                            </div>

                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }


    return (
        <>
            <Form.Control value={filteredData} onChange={(e) => handleChange(e)} />
            <div ref={dropdownref} className='custom-dropdown-items form-control hidden'>
                <Button variant="outline-secondary" className='w-100' onClick={() => setModalShow(true)}>
                    Add New Client
                </Button>
                {filterByData()}
            </div >


            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}







export default ClientDropDown