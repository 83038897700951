import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Purchase() {
    return (
        <>
            <div className='page-title'>Purchases</div>
            <main>
                <Link to="/New_Purchase" className='btn btn-primary'>New Purchase</Link>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Purchase Date</th>
                            <th>Vendor</th>
                            <th>Items</th>
                            <th>Qty</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                </Table>
            </main>
        </>
    )
}

export default Purchase