import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UpdateClientForm from '../../Components/UpdateClientForm'
import { GET_SINGLE_PARTNER } from '../../Const/ApiConst'
import { instance } from '../../Const/ApiHeader'



function UpdateClient() {


    const params = useParams()
    const [userData, setuserData] = useState([])

    const getData = async () => {
        const data = {
            id: params.id
        }
        try {
            await instance.post(
                GET_SINGLE_PARTNER,
                data
            ).then(res => {
                setuserData(res.data.data)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])



    const FormData = () => {
        return userData.map((res, i) => {
            return <UpdateClientForm key={i} obj={res} />
        })
    }


    return (
        <>
            <div className='page-title'>Update Client</div>
            <main>
                {FormData()}
            </main>
        </>
    )
}

export default UpdateClient