import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GET_ESTIMATE_CLIENT_VIEW_INVOICE, NEW_RECEIPT } from '../../Const/ApiConst'
import { instance } from '../../Const/ApiHeader'
import moment from 'moment'
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DataTable from 'react-data-table-component';
import { Alert, Form } from 'react-bootstrap'
import { Tooltip } from '@mui/material'


function ClientEstimateInvoice() {
  const param = useParams()

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [inputAmount, setInputAmount] = useState()
  const [paymentMode, setPaymentMode] = useState('Cash')
  const [invoiceId, setincoiceid] = useState()
  const [InvoiceType, setInvoiceType] = useState()
  const [referenceNo, setReferenceNo] = useState()

  const rightAlignStyule = {
    display: 'flex',
    justifyContent: 'end'
  }

  const middleAlign = {
    display: 'flex',
    alignItems: 'center'
  }
  const columns = [
    {
      name: 'Invoice Date',
      selector: row => moment(row.invoice_date).format("DD/MM/YYYY"),
    },
    {
      name: 'Invoice Number',
      selector: row => <Tooltip title={row.invoice_no}>
        <span>{row.invoice_no}</span>
      </Tooltip>,
    },
    {
      name: 'Location',
      selector: row => <Tooltip title={row.location}>
        <span>{row.location}</span>
      </Tooltip>,
    },
    {
      name: 'Event Duration',
      selector: row => row.no_of_days + ' Days',
    },
    {
      name: 'Amount',
      selector: row => parseFloat(row.invoiceTotal).toFixed(2),
      style: rightAlignStyule
    },
    {
      name: 'Transporting Charge',
      selector: row => parseFloat(row.transporting_charge).toFixed(2),
      style: rightAlignStyule
    },
    {
      name: 'Balance',
      selector: row => <Alert variant='warning' style={middleAlign} className='m-2'><strong>{(parseFloat(row.transporting_charge) + parseFloat(row.invoiceTotal) - parseFloat(row.discount) - parseFloat(row.payment_received)).toFixed(2)}</strong></Alert>,
      style: middleAlign
    },
    {
      name: 'Update Payment',
      selector: row => <Button
        size='small'
        variant='contained'
        color="warning"
        startIcon={<PreviewIcon />} onClick={() => handleOpen(row)}>Receipt</Button>
    }
  ];
  const getGstInvoice = async page => {
    setLoading(true);
    try {
      const data = {
        page: page,
        per_page: perPage,
        id: param.id
      }
      await instance.post(
        GET_ESTIMATE_CLIENT_VIEW_INVOICE,
        data
      ).then(response => {
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
      }).catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = page => {
    getGstInvoice(page);
    // eslint-disable-next-line
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const data = {
      page: page,
      per_page: newPerPage,
      id: param.id
    }
    const response = await instance.post(
      GET_ESTIMATE_CLIENT_VIEW_INVOICE,
      data
    )
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    getGstInvoice(1)
    // eslint-disable-next-line
  }, [])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '5px'
  };

  const [invNo, setInvNo] = useState()
  const [invDate, setInvDate] = useState()
  const [invoiceTotal, setInvoiceTotal] = useState()

  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    console.log(e);
    setInvNo(e.invoice_no)
    setInvDate(e.invoice_date)
    setOpen(true)
    setInvoiceTotal(parseFloat(e.invoiceTotal) + parseFloat(e.transporting_charge) - parseFloat(e.discount))
    setincoiceid(e._id)
    setInvoiceType(e.invoiceType)

  };
  const handleClose = () => setOpen(false);


  const newReceiptAction = async () => {

    try {
      const data = {
        invoice_id: invoiceId,
        inputAmount: inputAmount,
        paymentMode: paymentMode,
        customer_id: param.id,
        invoice_no: invNo,
        InvoiceType: InvoiceType,
        referenceNo: referenceNo
      }
      await instance.post(
        NEW_RECEIPT,
        data
      ).then(response => {
        console.log(response.data);
        // window.location.reload()
        getGstInvoice(1)
        handleClose()
      }).catch(erry => {
        console.log(erry);
      })
    } catch (errx) {
      console.log(errx);
    }
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Form>
          <Box sx={style} >
            <strong className='mb-2'>New Receipt</strong>
            <div className="estimate-item-container w-100">

              <div>
                <TextField size='small' defaultValue={invNo} label="Invoice No" disabled required />
              </div>
              <div>
                <TextField size='small' defaultValue={invDate} label="Invoice Date" disabled required />
              </div>
              <div>
                <TextField size='small' label="Total Amount" defaultValue={invoiceTotal} disabled required />
              </div>
            </div>
            <hr />
            <div className="estimate-item-container w-100">
              <div className='w-100'>
                <TextField size='small' label="Amount" onChange={(e) => setInputAmount(e.target.value)} required />
              </div>
              <div className='w-100'>
                <FormControl size='small' fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Payment Mode</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Payment Mode"
                    defaultValue='Cash'
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <MenuItem value={'Cash'}>Cash</MenuItem>
                    <MenuItem value={'UPI'}>UPI</MenuItem>
                    <MenuItem value={'BANK Transfer'}>BANK Transfer</MenuItem>
                    <MenuItem value={'Card'}>Card</MenuItem>
                    <MenuItem value={'Cheque'}>Cheque</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className='mt-3'>
              <TextField
                size='small'
                fullWidth label="Payment Reference"
                onChange={(e) => setReferenceNo(e.target.value)} />
            </div>
            <div className='mt-3'>
              <Button variant='contained' onClick={() => newReceiptAction()}>Save</Button>
            </div>

          </Box>
        </Form>
      </Modal>
    </>
  )
}

export default ClientEstimateInvoice