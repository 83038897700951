import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { ADD_PARTNER } from '../../Const/ApiConst'
import SaveIcon from '@mui/icons-material/Save';
import { instance } from '../../Const/ApiHeader'

function AddClient() {

    const navigate = useNavigate()

    const [name, setName] = useState()
    const [contact, setContact] = useState()
    const [email, setEmail] = useState()
    const [gst, setGst] = useState()
    const [state, setState] = useState()
    const [stateCode, setStateCode] = useState()
    const [pointOfContact, setPOintOfConact] = useState()
    const [address, setAddress] = useState()
    const [Pincode, setPincode] = useState()

    const saveForm = async (e) => {
        e.preventDefault()
        const data = {
            name: name,
            contact: contact,
            email: email,
            gst: gst,
            state: state,
            stateCode: stateCode,
            pointOfContact: pointOfContact,
            address: address,
            Pincode: Pincode,
            PartnerType: "Client"
        }

        try {
            await instance.post(
                ADD_PARTNER,
                data
            ).then(res => {
                navigate("/Clients")
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <>
            <div className='page-title'>Add Client</div>
            <div style={{ padding: '10px' }}>
                <Link to="/Clients"><Button variant='contained' color='success'>Back</Button></Link>
            </div>
            <main>
                <Form onSubmit={(e) => saveForm(e)} autoComplete='off'>
                    <Form.Group className='form-row'>
                        <Form.Group className='form-col'>
                            <strong>Basic Details</strong>
                        </Form.Group>
                        <Form.Group className='form-col'>
                            <TextField
                                size="small"
                                required
                                label="Company Name"
                                value={name}
                                onChange={(e) => setName(
                                    e.target.value
                                        .toLowerCase()
                                        .split(' ')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' '))} />
                            <TextField
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                size="small"
                                label="Contact No"
                                onChange={(e) => setContact(e.target.value)} />
                            <TextField
                                size="small"
                                label="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                        </Form.Group>
                    </Form.Group>
                    <hr />
                    <Form.Group className='form-row'>
                        <Form.Group className='form-col'>
                            <strong>GST</strong>
                        </Form.Group>
                        <Form.Group className='form-col'>
                            <TextField
                                size="small"
                                label="GST No"
                                value={gst}
                                onChange={(e) => setGst(
                                    e.target.value
                                        .toUpperCase())}
                            />
                            <TextField
                                size="small"
                                label="State"
                                defaultValue={'Kerala'}
                                value={state}
                                onChange={(e) => setState(
                                    e.target.value
                                        .toLowerCase()
                                        .split(' ')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' '))} />
                            <TextField
                                size="small"
                                type='number'
                                defaultValue={32}
                                label="State Code"
                                onChange={(e) => setStateCode(e.target.value)} />
                        </Form.Group>
                    </Form.Group>
                    <hr />
                    <Form.Group className='form-row'>
                        <Form.Group className='form-col'>
                            <strong>Contact</strong>
                        </Form.Group>
                        <Form.Group className='form-col'>
                            <TextField
                                size="small"
                                label="Point of Contact"
                                value={pointOfContact}
                                onChange={(e) => setPOintOfConact(
                                    e.target.value
                                        .toLowerCase()
                                        .split(' ')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' '))} />
                            <TextField
                                size="small"
                                label="Address"
                                value={address}
                                onChange={(e) => setAddress(
                                    e.target.value
                                        .toLowerCase()
                                        .split(' ')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' '))} />
                            <TextField
                                size="small"
                                label="Pin Code"
                                type='number'
                                onChange={(e) => setPincode(e.target.value)} />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className='form-row'>
                        <div className='form-col'></div>
                        <div className='form-col mt-4'>
                            <Button variant='contained' color='success' startIcon={<SaveIcon />} type='submit' >Save</Button>
                        </div>

                    </Form.Group>
                </Form>
            </main>
        </>
    )
}

export default AddClient