import { Alert, TextField } from '@mui/material'
import React, { useState } from 'react'
import {  Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from '../../Const/ApiConst'
import { instance } from '../../Const/ApiHeader'
import './Login.css'
import { LoadingButton } from '@mui/lab'



function Login() {
    const [uname, setUname] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState('')
    const [loginLoading, setLoginLoading] = useState(false)
    const navigate = useNavigate();

    const LoginAction = (e) => {
        e.preventDefault()
        setLoginLoading(true)
        if (uname === "" || uname === undefined) {
            setError("User Name Cannot be empty")
            setLoginLoading(false)
        } else if (password === "" || password === undefined) {
            setLoginLoading(false)
            setError("Password Cannot be empty")
        } else {
            const data = {
                uname: uname,
                password: password
            }

            instance.post(
                LOGIN,
                data
            ).then(res => {
                navigate('/')
                window.location.reload()
                sessionStorage.setItem("wsstfaarvav", res.data.token)
                setLoginLoading(false)

            }).catch(err => {
                setLoginLoading(false)
                switch (err.response.status) {
                    case 404:
                        setError("User not Exists!")
                        break;
                    case 401:
                        setError('Invalid Password')
                        break;
                    default:
                        break;
                }
            })

        }


    }

    return (
        <>

            <div className='login-bg'>
                <div>

                </div>
                <div>
                </div>
            </div>
            <Form onSubmit={(e) => LoginAction(e)} className='loginContainer'>
                <h1 className='text-center' style={{ fontFamily: 'Great Vibes', fontWeight: 'bold' }}>Kumar Decorations</h1>
                {error ? (<Alert severity="error">{error}</Alert>) : (<></>)}
                <TextField
                    size='small'
                    label="User Name"
                    onChange={(e) => setUname(e.target.value)} />
                <TextField
                    size='small'
                    label="Password"
                    type='password'
                    onChange={(e) => setPassword(e.target.value)} />
                <LoadingButton
                    loading={loginLoading}
                    type='submit'>Login</LoadingButton>
            </Form></>
    )
}

export default Login