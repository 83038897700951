import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import { instance } from '../../Const/ApiHeader';
import { GET_CLIENT_RECEIPT } from '../../Const/ApiConst';
import DataTable from 'react-data-table-component';
import { Tooltip } from '@mui/material';


function ClientReceipts() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const params = useParams()

    const amountColumnStyle = {
        display: 'flex',
        justifyContent: 'end',
    }

    const columns = [
        {
            name: 'Receipt Date',
            selector: row => moment(row.receipt_date).format("DD/MM/YYYY"),
            // sortable: true,
        },
        {
            name: 'Receipt No',
            selector: row => <Tooltip title={row.receipt_number}>
                <span>{row.receipt_number}</span>
            </Tooltip>
        },
        {
            name: 'Mode of payment',
            selector: row => row.mode_of_payment
        },
        {
            name: 'Reference',
            selector: row => row.referenceNo
        },
        {
            name: 'Amount',
            selector: row => parseFloat(row.payment_received).toFixed(2),
            style: amountColumnStyle,
        },


    ];

    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage,
            clientid: params.id
        }
        const response = await instance.post(
            GET_CLIENT_RECEIPT,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage,
            clientid: params.id
        }
        const response = await instance.post(
            GET_CLIENT_RECEIPT,
            data
        )

        console.log(response.data.data);
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </>
    )
}

export default ClientReceipts