import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment'
import ReactToPrint from 'react-to-print';
import { instance } from '../../Const/ApiHeader';
import { GET_ESTIMATES_VIEW } from '../../Const/ApiConst';
import { ToWords } from 'to-words';
import { Button } from '@mui/material';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { BANK, BANK_ACCOUNT, BRANCH, COMPANY_ADDRESS, COMPANY_CONTACT_NUMBER, COMPANY_NAME, IFSC } from '../../Const/AppConfig';


const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Rupee',
            plural: 'Rupees',
            symbol: '₹',
            fractionalUnit: {
                name: 'Paisa',
                plural: 'Paise',
                symbol: '',
            },
        }
    }
});


function ViewInvoice() {
    // const toWords = new ToWords();

    const componentRef = useRef();
    const params = useParams()
    const [esitmateData, setEstimateData] = useState([])
    const [custData, setCustData] = useState([])



    const fetchEstimateDetails = async () => {
        try {
            const data = {
                id: params.id
            }
            await instance.post(
                GET_ESTIMATES_VIEW,
                data
            ).then(res => {
                setEstimateData(res.data.data)
                setCustData(res.data.cust_data)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchEstimateDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    var slno = 0;
    var sum = 0;



    return (
        <>
            <div style={{ padding: '10px' }}><Link to="/Estimate"><Button variant='contained' color='success' >Back</Button></Link></div>
            <main style={{ overflow: 'auto' }}>
                {esitmateData.map((res, i) => (


                    <div ref={componentRef} key={i} className='p-4' id="pdf-content" style={{ minWidth: '750px', overflow: 'auto' }}>
                        <div>
                            <h1 className='text-center mt-4 logoText'>{COMPANY_NAME}</h1>
                            <div className='text-center'>
                                {COMPANY_ADDRESS}<br />
                                MOB : {COMPANY_CONTACT_NUMBER}
                            </div>
                            <div>
                                <div className='invoiceTitleContainer'>CASH BILL</div>
                                <hr />
                                <div className='d-flex justify-content-between pt-2 ps-4 pe-4'>
                                    <div>
                                        <div className='d-flex justify-content-between  pt-2 ps-4 pe-4'>
                                            <div className='d-flex flex-column'>
                                                <div>To,</div>
                                                {custData.map((custres, i) => (
                                                    <div key={i}>
                                                        <div>{custres.PartnerName}</div>
                                                        <div>{custres.Address}</div>
                                                        <div>Mob : {custres.ContactNo}</div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex flex-column text-end'>
                                        <div>Inv No: {res.invoice_no}</div>
                                        <div>Date: {moment(res.invoice_date).format("DD/MM/YYYY")}</div>
                                        <div>Venue : {res.location}</div>
                                        <div>No of Days: {res.no_of_days} Days</div>
                                        <div>Event Days: {res.eventFromDate}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <table className="printTable w-100">
                            <thead className='printThead'>
                                <tr>
                                    <th className='p-2' style={{ width: '60px', border: '1px solid black' }}>Sl No</th>
                                    <th style={{ width: '400px', border: '1px solid black' }}>Description</th>
                                    <th style={{ width: '80px', textAlign: 'center', border: '1px solid black' }}>Unit</th>
                                    <th className='text-end pe-4' style={{ border: '1px solid black' }}>Qty</th>
                                    <th className='text-end pe-4' style={{ border: '1px solid black' }}>Rate</th>
                                    <th className='text-end pe-4' style={{ border: '1px solid black' }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {res.items.map((item, i) => (
                                    <tr style={{ height: '15px' }} key={i}>
                                        <td style={{ width: '60px', borderLeft: '1px solid black', borderRight: '1px solid black', textAlign: 'center' }}>{slno = slno + 1}</td>
                                        <td style={{ width: '400px', borderRight: '1px solid black' }}>&nbsp;{item.item_name}</td>
                                        <td style={{ width: '80px', borderRight: '1px solid black', textAlign: 'center' }}>{item.unit}</td>
                                        <td style={{ width: '80px', borderRight: '1px solid black', textAlign: 'center' }}>{item.qty}</td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{item.rate}</td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }} >{item.rate * item.qty}<div style={{ display: 'none' }}>{sum = sum + item.rate * item.qty}</div></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr style={{ borderLeft: '1px solid black', borderTop: '1px solid black' }}>
                                    <th colSpan={5} className='text-end pe-4'>Total</th>
                                    <th className='text-end pe-4' style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>{sum}</th>
                                </tr>
                            </tfoot>
                        </table>
                        <div className='d-flex p-2'>
                            <div style={{ width: "70%" }}>
                                <div
                                    style={{
                                        borderTop: '1px solid black',
                                        borderLeft: '1px solid black',
                                        borderBottom: '1px solid black'
                                    }}
                                    className='p-2'>
                                    <div>In figures : {toWords.convert(sum + parseFloat(res.transporting_charge) - parseFloat(res.discount).toFixed(2))}</div>
                                </div>
                                <div
                                    style={{
                                        borderLeft: '1px solid black',
                                        borderBottom: '1px solid black'
                                    }} className='p-2'>
                                    <div><strong>BANK Details</strong></div>
                                    <div className='inv-bank-container'>
                                        <div>Account No</div>
                                        <div>: {BANK_ACCOUNT}</div>
                                        <div>IFSC Code</div>
                                        <div>: {IFSC}</div>
                                        <div>Bank</div>
                                        <div>: {BANK}</div>
                                        <div>Branch</div>
                                        <div>: {BRANCH}</div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        borderLeft: '1px solid black',
                                        borderBottom: '1px solid black',
                                    }} className='p-2'>
                                    Narration: {res.narration}
                                </div>
                            </div>
                            <div style={{ border: '1px solid black', width: "30%" }} className='p-2'>
                                <div className='d-flex justify-content-between'>
                                    <div>Total</div>
                                    <div>{sum}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Discount</div>
                                    <div>{res.discount}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Transporting Charge</div>
                                    <div>{res.transporting_charge}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <strong>Grand Total</strong>
                                    <strong>{sum + parseFloat(res.transporting_charge) - parseFloat(res.discount).toFixed(2)}</strong>
                                </div>
                                <hr />
                                <div>
                                    For,<br />
                                    <strong>Kumar Decorations</strong>

                                </div>
                            </div>

                        </div>
                    </div>

                ))}
                <ReactToPrint
                    trigger={() => <Button variant='contained' startIcon={<LocalPrintshopIcon />}>Print</Button>}
                    content={() => componentRef.current}
                />
                {/* <Button variant='outlined' startIcon={<DownloadIcon />} className='ms-2' onClick={() => downloadPDF('Estimate')} disabled>Download</Button> */}
            </main >
        </>
    )
}

export default ViewInvoice