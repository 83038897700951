import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { instance } from '../../Const/ApiHeader'
import { GET_ESTIMATE_INVOICE_DATA, GET_ESTIMATE_INVOICE_DATA_PAGE } from '../../Const/ApiConst';
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { Button } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';


function EstimateTable() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const columns = [
        {
            name: 'Estimate Date',
            selector: row => moment(row.invoice_date).format("DD/MM/YYYY"),
        },
        {
            name: 'cust_id',
            selector: row => row.cust_id,
        },
        {
            name: 'Location',
            selector: row => {
                row.items.map((res, i) => (
                    <>
                        <div>{res.item_name}</div>
                    </>
                ))
            },
        },
        {
            name: 'Event Duration',
            selector: row => row.no_of_days,
        },
        {
            name: 'Amount',
            selector: row => row.invoiceTotal,
        },
        {
            name: '',
            selector: row => <Link to={'/View_Estimate/' + row._id}><Button variant='contained' size='small' startIcon={<PreviewIcon />}>New Receipt</Button></Link>
        }
    ];
    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        const response = await instance.post(
            GET_ESTIMATE_INVOICE_DATA,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_ESTIMATE_INVOICE_DATA_PAGE,
            data
        )

        console.log(response.data.data);
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
    />

    )
}

export default EstimateTable