import React from 'react'

function NewPurchase() {

    return (
        <>
            <div className='page-title'>New Purchase</div>

            <main>

            </main>
        </>
    )
}

export default NewPurchase