import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ADD_PARTNER } from '../../Const/ApiConst'
import SaveIcon from '@mui/icons-material/Save';
import { instance } from '../../Const/ApiHeader'

function AddUser() {
    const navigate = useNavigate()

    const [name, setName] = useState()
    const [contact, setContact] = useState()
    const [email, setEmail] = useState()
    const [gst, setGst] = useState()
    const [state, setState] = useState()
    const [stateCode, setStateCode] = useState()
    const [pointOfContact, setPOintOfConact] = useState()
    const [address, setAddress] = useState()
    const [Pincode, setPincode] = useState()


    const saveForm = async (e) => {
        e.preventDefault()
        const data = {
            name: name,
            contact: contact,
            email: email,
            gst: gst,
            state: state,
            stateCode: stateCode,
            pointOfContact: pointOfContact,
            address: address,
            Pincode: Pincode,
            PartnerType: "Vendor"
        }

        try {
            await instance.post(
                ADD_PARTNER,
                data
            ).then(res => {
                navigate("/Vendors")
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <>
            <div className='page-title'>Add Vendor</div>
            <main>
                <Form onSubmit={(e) => saveForm(e)} autoComplete='off'>
                    <Form.Group className='form-row'>
                        <Form.Group className='form-col'>
                            <strong>Basic Details</strong>
                        </Form.Group>
                        <Form.Group className='form-col'>
                            <TextField size="small" required label="Vendor Name" onChange={(e) => setName(e.target.value)} />
                            <TextField size="small" required label="Contact No" onChange={(e) => setContact(e.target.value)} />
                            <TextField size="small" label="Email Address" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                    </Form.Group>
                    <hr />
                    <Form.Group className='form-row'>
                        <Form.Group className='form-col'>
                            <strong>GST</strong>
                        </Form.Group>
                        <Form.Group className='form-col'>
                            <TextField size="small" label="GST No" onChange={(e) => setGst(e.target.value)} />
                            <TextField size="small" label="State" onChange={(e) => setState(e.target.value)} />
                            <TextField size="small" label="State Code" onChange={(e) => setStateCode(e.target.value)} />
                        </Form.Group>
                    </Form.Group>
                    <hr />
                    <Form.Group className='form-row'>
                        <Form.Group className='form-col'>
                            <strong>Contact</strong>
                        </Form.Group>
                        <Form.Group className='form-col'>
                            <TextField size="small" required label="Point of Contact" onChange={(e) => setPOintOfConact(e.target.value)} />
                            <TextField size="small" label="Address" onChange={(e) => setAddress(e.target.value)} />
                            <TextField size="small" label="Pin Code" onChange={(e) => setPincode(e.target.value)} />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group className='form-row'>
                        <div className='form-col'></div>
                        <div className='form-col mt-4'>
                            <Button variant='contained' startIcon={<SaveIcon/>} type='submit' >Save</Button>
                        </div>

                    </Form.Group>
                </Form>
            </main>
        </>
    )
}

export default AddUser