import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React from 'react'
import EstimateComponent from './EstimateComponent';
import GSTComponent from './GSTComponent';
import ExpenseComponent from './ExpenseComponent';

function Reports() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div className='page-title'>Reports</div>
            <main>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Estimate Report" value="1" />
                            <Tab label="GST Report" value="2" />
                            {/* <Tab label="Income Report" value="3" /> */}
                            <Tab label="Expense Report" value="4" />
                            {/* <Tab label="Payment Shorts" value="5" /> */}
                        </TabList>
                    </Box>
                    <TabPanel value="1"><EstimateComponent /></TabPanel>
                    <TabPanel value="2"><GSTComponent /></TabPanel>
                    {/* <TabPanel value="3">Income Report</TabPanel> */}
                    <TabPanel value="4"><ExpenseComponent /></TabPanel>
                    {/* <TabPanel value="5">Pyment Shorts</TabPanel> */}
                </TabContext>
            </main>




        </div>
    )
}

export default Reports