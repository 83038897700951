import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import moment from 'moment'
import { ToWords } from 'to-words';
import ReactToPrint from 'react-to-print';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Button } from '@mui/material';
import { GET_GST_INVOICE } from '../../Const/ApiConst';
import { instance } from '../../Const/ApiHeader';
import { BANK, BANK_ACCOUNT, BRANCH, COMPANY_ADDRESS, COMPANY_CONTACT_NUMBER, COMPANY_GST, COMPANY_NAME, IFSC } from '../../Const/AppConfig';

const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Rupee',
            plural: 'Rupees',
            symbol: '₹',
            fractionalUnit: {
                name: 'Paisa',
                plural: 'Paise',
                symbol: '',
            },
        }
    }
});


function ViewGSTInvoice() {

    const componentRef = useRef();
    const params = useParams()
    const [esitmateData, setEstimateData] = useState([])
    const [custData, setCustData] = useState([])
    var slno = 0;
    var sum = 0;

    const fetchEstimateDetails = async () => {
        try {
            const data = {
                id: params.id
            }
            await instance.post(
                GET_GST_INVOICE,
                data
            ).then(res => {
                setEstimateData(res.data.data)
                setCustData(res.data.cust_data)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchEstimateDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // var noofdays = 0;
    var taxablevalue = 0;
    var halftotaltax = 0;
    var totalFinalTaxableValue = 0;
    var totalFinalTax = 0;
    return (
        <>
            <div style={{ padding: '10px' }}>
                <Link to="/Billing">
                    <Button variant='contained' color='success'>Back</Button>
                </Link>
            </div>
            <main style={{ overflow: 'auto' }}  >
                {esitmateData.map((res, i) => (


                    <div ref={componentRef} key={i} className='m-4' id="pdf-content" style={{ minWidth: '750px', overflow: 'auto', border: '1px solid black' }}>
                        <div>
                            <h1 className='text-center mt-4 logoText'>{COMPANY_NAME}</h1>
                            <div className='text-center'>
                                {COMPANY_ADDRESS}<br />
                                MOB : {COMPANY_CONTACT_NUMBER}<br />
                                {COMPANY_GST}
                            </div>
                            <div>
                                <div className='invoiceTitleContainer'>INVOICE</div>
                                <div className='d-flex justify-content-between pt-2 ps-4 pe-4'>
                                    <div>
                                        <div className='d-flex justify-content-between  pt-2 ps-4 pe-4'>
                                            <div className='d-flex flex-column'>
                                                <div>To,</div>
                                                {custData.map((custres, i) => (
                                                    <div key={i}>
                                                        <div><strong>{custres.PartnerName} ,</strong></div>
                                                        <div>{custres.Address} ,</div>
                                                        {/* <div>Mob : {custres.ContactNo}</div> */}
                                                        <div>GST No : {custres.GSTNo}</div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex flex-column text-end mb-2'>
                                        <div>Inv No: {res.invoice_no}</div>
                                        <div>Date: {moment(res.invoice_date).format("DD/MM/YYYY")}</div>
                                        <div>Venue : {res.location}</div>
                                        <div>No of Days: {res.no_of_days} Days</div>
                                        <div>Event Day: {res.eventFromDate}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <table className="printTable w-100" style={{ fontSize: '12px' }}>
                            <thead className='printThead'>
                                <tr>
                                    <th rowSpan={2} className='p-2' style={{ border: '1px solid black', width: "5%" }}>Sl No</th>
                                    <th rowSpan={2} className='text-center' style={{ border: '1px solid black', width: "19%" }}>Description</th>
                                    <th rowSpan={2} className='text-center' style={{ border: '1px solid black', width: "9%" }}>HSN / SAC</th>
                                    <th rowSpan={2} className='text-center' style={{ border: '1px solid black', width: "5%" }}>Qty</th>
                                    <th rowSpan={2} style={{ textAlign: 'center', border: '1px solid black', width: "9%" }}>Unit</th>
                                    <th rowSpan={2} className='text-center  ' style={{ border: '1px solid black', width: "9%" }}>Rate</th>
                                    {/* <th rowSpan={2} className='text-center pe-4' style={{ border: '1px solid black' }}>No of Days</th> */}
                                    <th rowSpan={2} className='text-center  ' style={{ border: '1px solid black', width: "7%" }}>Taxable Value</th>
                                    <th colSpan={2} className='text-center ' style={{ border: '1px solid black', width: "11%" }}>CGST</th>
                                    <th colSpan={2} className='text-center ' style={{ border: '1px solid black', width: "11%" }}>SGST</th>
                                    <th rowSpan={2} className='text-center ' style={{ border: '1px solid black', width: "9%" }}>Total</th>
                                </tr>
                                <tr>
                                    <th className="text-center" style={{ border: '1px solid black' }}>%</th>
                                    <th className="text-center" style={{ border: '1px solid black' }}>Value</th>
                                    <th className="text-center" style={{ border: '1px solid black' }}>%</th>
                                    <th className="text-center" style={{ border: '1px solid black' }}>Value</th>
                                </tr>
                            </thead>
                            <tbody >
                                {res.items.map((item, i) => (
                                    <tr style={{ height: '15px' }} className='gstBillItemFontSize' key={i}  >
                                        <td style={{ borderLeft: '1px solid black', borderRight: '1px solid black', textAlign: 'center' }}>{slno = slno + 1}</td>
                                        <td style={{ borderRight: '1px solid black' }}>&nbsp;{item.item_name}</td>
                                        <td style={{ borderRight: '1px solid black', textAlign: 'center' }} >{item.hsn}</td>
                                        <td style={{ borderRight: '1px solid black', textAlign: 'center' }}>{item.qty}</td>
                                        <td style={{ borderRight: '1px solid black', textAlign: 'center' }}>{item.unit}</td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{parseFloat(item.rate).toFixed(2)}</td>
                                        {/* <td className='text-center' style={{ borderRight: '1px solid black' }}>{noofdays}</td> */}
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{parseFloat(taxablevalue = item.rate * item.qty).toFixed(2)} <div style={{ display: 'none' }}>{totalFinalTaxableValue = totalFinalTaxableValue + taxablevalue}</div></td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{item.tax / 2} %</td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{(taxablevalue * item.tax / 2 / 100).toFixed(2)}</td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{item.tax / 2} %</td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }}>{(halftotaltax = taxablevalue * item.tax / 2 / 100).toFixed(2)} <div style={{ display: 'none' }}>{totalFinalTax = totalFinalTax + halftotaltax * 2}</div></td>
                                        <td className='text-end pe-2' style={{ borderRight: '1px solid black' }} >{(taxablevalue + halftotaltax * 2).toFixed(2)}<div style={{ display: 'none' }}>{sum = sum + taxablevalue + halftotaltax * 2}</div></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    {/* <td style={{ borderRight: '1px solid black' }}></td> */}
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                    <td style={{ borderRight: '1px solid black' }}></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='d-flex pt-2'>
                            <div style={{ width: "70%" }}>
                                <div
                                    style={{
                                        borderTop: '1px solid black',
                                        borderLeft: '1px solid black',
                                        borderBottom: '1px solid black'
                                    }}
                                    className='p-2'>
                                    <div>In figures : {toWords.convert(sum - parseFloat(res.discount) + parseFloat(res.transporting_charge))}</div>
                                </div>
                                <div
                                    style={{
                                        borderLeft: '1px solid black',
                                        borderBottom: '1px solid black'
                                    }} className='p-2'>
                                    <div><strong>BANK Details</strong></div>
                                    <div className='inv-bank-container'>
                                        <div>Account No</div>
                                        <div>: {BANK_ACCOUNT}</div>
                                        <div>IFSC Code</div>
                                        <div>: {IFSC}</div>
                                        <div>Bank</div>
                                        <div>: {BANK}</div>
                                        <div>Branch</div>
                                        <div>: {BRANCH}</div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        borderLeft: '1px solid black',
                                        borderBottom: '1px solid black',
                                    }} className='p-2'>
                                    Narration: {res.narration}<br />
                                    {/* Transporting Charge : {res.transporting_charge} */}
                                </div>
                            </div>
                            <div style={{ border: '1px solid black', width: "30%" }} className='p-2'>
                                <div className='d-flex justify-content-between'>
                                    <div>Taxable Value</div>
                                    <div>{(totalFinalTaxableValue).toFixed(2)}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Tax</div>
                                    <div>{(totalFinalTax).toFixed(2)}</div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div>Discount</div>
                                    <div>{(res.discount).toFixed(2)}</div>
                                </div>
                                {/* <div className='d-flex justify-content-between'>
                                    <div>Transporting Charge</div>
                                    <div>{(res.transporting_charge).toFixed(2)}</div>
                                </div> */}
                                <div className='d-flex justify-content-between'>
                                    <strong>Grand Total</strong>
                                    <strong>{(sum - parseFloat(res.discount) + parseFloat(res.transporting_charge)).toFixed(0)}.00</strong>
                                </div>
                                <hr />
                                <div>
                                    For,<br />
                                    <strong>Kumar Decorations</strong>
                                </div>
                            </div>

                        </div>
                    </div>

                ))}
                <ReactToPrint
                    trigger={() => <Button variant='contained' startIcon={<LocalPrintshopIcon />}>Print</Button>}
                    content={() => componentRef.current}
                />
                {/* <Button variant='outlined' startIcon={<DownloadIcon />} className='ms-2' onClick={() => downloadPDF('Estimate')} disabled>Download</Button> */}
            </main >
        </>
    )
}

export default ViewGSTInvoice