import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { instance } from '../../Const/ApiHeader'
import { View_ESTIMATE_RETURN } from '../../Const/ApiConst'
import moment from 'moment'
import DataTable from 'react-data-table-component'

function SalesReturn() {
    const [salesReturnData, setSalesReturnData] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            name: 'Sales Return No',
            selector: row => row.estimate_return_id
        },
        {
            name: 'Invoice Date',
            selector: row => moment(row.invoiceDate).format("DD/MM/YYYY"),
        },
        {
            name: 'Invoice No',
            selector: row => row.invoiceNo
        },
        {
            name: 'Narration`',
            selector: row => row.description
        },
        {
            name: 'Return Date',
            selector: row => moment(row.return_date).format("DD/MM/YYYY"),
        },
        {
            name: '',
            selector: row => <Link to={"/View_Credit_Note/" + row._id}><Button size='small' variant='contained' color='warning'>Preview</Button></Link>
        },
    ]

    const fetchData = async page => {
        setLoading(true);
        try {
            const data = {
                page: page,
                per_page: perPage
            }
            await instance.post(
                View_ESTIMATE_RETURN,
                data
            ).then(response => {
                setSalesReturnData(response.data.data)
                setTotalRows(response.data.total);
                setLoading(false);
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handlePageChange = page => {
        fetchData(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            View_ESTIMATE_RETURN,
            data
        )

        setSalesReturnData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };


    useEffect(() => {
        fetchData(1)
        // eslint-disable-next-line
    }, [])

    const searchAction = async (e, page) => {
        setLoading(true);
        const data = {
            page: 1,
            per_page: perPage,
            searchKey: e.target.value
        }
        const response = await instance.post(
            View_ESTIMATE_RETURN,
            data
        )
        setSalesReturnData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }


    return (
        <>
            <div className='page-title'>Sales Return</div>
            <div style={{ padding: '10px' }}><Link to='/New_Return'><Button variant='contained' color='success'>New Entry</Button></Link></div>
            <main>
                <div className='col-12 col-sm-3'>
                    <Form.Control
                        type="search"
                        className='mb-2'
                        placeholder='Search Invoice No...'
                        onChange={(e) => searchAction(e)} />
                </div>
                <DataTable
                    columns={columns}
                    data={salesReturnData}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />

            </main>
        </>
    )
}

export default SalesReturn