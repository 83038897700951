import { Button, FormHelperText, Table } from '@mui/material'
import React, { useRef, useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ReactToPrint from 'react-to-print';
import { instance } from '../../Const/ApiHeader';
import { GET_CLIENT_ACCOUNT_SUMMARY, GET_SINGLE_PARTNER } from '../../Const/ApiConst';
import { COMPANY_NAME } from '../../Const/AppConfig'
import { useParams } from 'react-router-dom';
import moment from 'moment'

function ClientStatement() {
    const params = useParams()
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const [invType, setInvType] = useState()
    const componentRef = useRef();
    const [fromDateError, setFromDatError] = useState(false)
    const [todateError, setTodateError] = useState(false)
    const [invTypeError, setInvTypeError] = useState(false)
    const helperText = "Required"
    const [invoiceList, setInvoiceList] = useState([])
    const [receiptList, setReceiptList] = useState([])
    const [opening_balance, setOpeningBalance] = useState(0)
    const [salesReturnData, setSalesReturnData] = useState([])
    const [accountSummaryList, setAccountSummaryList] = useState([])

    var total = opening_balance;
    const [company, setCompany] = useState()
    const [comAddress, setAddresss] = useState()
    const [picode, setPincode] = useState()

    const paramx = useParams()

    const customerDetails = async () => {
        try {
            const data = {
                id: paramx.id
            }
            await instance.post(
                GET_SINGLE_PARTNER,
                data
            ).then(response => {
                response.data.data.forEach(element => {
                    setCompany(element.PartnerName)
                    setAddresss(element.Address)
                    setPincode(element.PinCode)
                });
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        customerDetails()
        // eslint-disable-next-line
    }, [])


    const setInvTypeAction = (e) => {
        setInvType(e)
        setInvTypeError(false)
    }
    const setFromDateAction = (e) => {
        setFromDate(e)
        setFromDatError(false)
    }
    const setToDateAction = (e) => {
        setToDate(e)
        setTodateError(false)
    }

    const getSummary = async () => {
        var xList = []
        if (toDate !== undefined && fromDate !== undefined && invType !== undefined) {
            try {
                const data = {
                    cust_id: params.id,
                    invType: invType,
                    fromDate: fromDate,
                    toDate: toDate,
                }
                await instance.post(
                    GET_CLIENT_ACCOUNT_SUMMARY,
                    data
                ).then(response => {
                    setSalesReturnData(response.data.salesReturn)
                    setInvoiceList(response.data.data.gstBills)
                    setReceiptList(response.data.data.receipts)
                    setOpeningBalance(response.data.data.opening_blance);

                    response.data.data.gstBills.forEach(res => {
                        var amount = parseFloat(res.invoiceTotal) + parseFloat(res.transporting_charge) - parseFloat(res.discount)
                        xList.push({
                            "cust_id": res._id,
                            "date": res.invoice_date,
                            "inv_no": res.invoice_no,
                            "narration": res.narration,
                            "type": "Invoice",
                            "category": "Expense",
                            "amount": amount.toFixed(2),
                        })
                    });

                    response.data.data.receipts.forEach(res => {
                        xList.push({
                            "cust_id": "res._id",
                            "date": res.receipt_date,
                            "inv_no": res.invoice_no,
                            "narration": res.referenceNo,
                            "type": "Receipt",
                            "category": "Income",
                            "amount": res.payment_received.toFixed(2),
                        })
                    });
                    response.data.data.salesReturn.forEach(res => {
                        xList.push({
                            "cust_id": "res._id",
                            "date": res.return_date,
                            "inv_no": res.estimate_return_id,
                            "narration": res.description,
                            "type": "Sales Return",
                            "category": "Sales Return",
                            "amount": res.return_total.toFixed(2),
                        })
                    });
                    console.log(xList);
                    setAccountSummaryList(xList)
                    accountSummaryList.sort((a, b) => a.date.localeCompare(b.date))


                })
            } catch (error) {
                console.log(error);
            }
        } else {
            if (toDate === undefined) {
                setTodateError(true)
            }

            if (fromDate === undefined) {
                setFromDatError(true)
            }

            if (invType === undefined) {
                setInvTypeError(true)
            }
        }
    }


    return (
        <>
            <div>
                <div className='client-statement-filter'>
                    <div>
                        <label><small className='text-secondary'>Invoice Type</small></label>
                        <select
                            className='form-control'
                            onChange={(e) => setInvTypeAction(e.target.value)}>
                            <option hidden>Select Invoice</option>
                            <option>Estimate</option>
                            <option>GST Invoice</option>
                        </select>
                        {invTypeError && <FormHelperText error={fromDateError}>{helperText}</FormHelperText>}
                    </div>
                    <div>
                        <label><small className='text-secondary'>From Date</small></label>
                        <Form.Control
                            type='date'
                            onChange={(e) => setFromDateAction(e.target.value)} />
                        {fromDateError && <FormHelperText error={fromDateError}>{helperText}</FormHelperText>}
                    </div>
                    <div>
                        <label><small className='text-secondary'>To Date</small></label>
                        <Form.Control
                            type='date'
                            onChange={(e) => setToDateAction(e.target.value)} />
                        {todateError && <FormHelperText error={todateError}>{helperText}</FormHelperText>}
                    </div>
                    <div className='d-flex align-items-end'>
                        <Button
                            variant='contained'
                            color='success'
                            startIcon={<ManageSearchIcon />}
                            onClick={() => getSummary()}>Find</Button>

                    </div>
                </div>

                <hr />
                <div className='mt-3' ref={componentRef}>
                    <div className='p-3'>

                        <div>
                            <h1 className='text-center mt-4 logoText'>{COMPANY_NAME}</h1>
                            <div className='text-center'><strong>Account Statement</strong></div>
                            <hr />
                        </div>
                        <div>

                            <div className='d-flex justify-content-between'>
                                <div>
                                    <i>To,</i>
                                    <div>{company}</div>
                                    <div>{comAddress}</div>
                                    <div>{picode}</div>
                                </div>
                                <div>
                                    <div>
                                        <div className='bg-secondary text-white text-center'><strong>Statement Date</strong></div>
                                        <div className='text-end'>{moment(new Date()).format('DD-MM-YYYY')}</div>
                                    </div>
                                    <div>
                                        <div className='bg-secondary text-white text-center'><strong>Statement Period</strong></div>
                                        <div className='text-end'>{moment(fromDate).format('DD-MM-YYYY')} to {moment(toDate).format('DD-MM-YYYY')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-2' style={{ fontSize: '14px', overflow: 'auto' }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th className='text-center bg-black text-white p-2'>Date</th>
                                    <th className='text-center bg-black text-white p-2'>Transaction</th>
                                    <th className='text-center bg-black text-white p-2'>Narration</th>
                                    <th className='text-end bg-black text-white p-2'>Amount</th>
                                    <th className='text-end bg-black text-white p-2'>Payments</th>
                                    <th className='text-end bg-black text-white p-2'>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={5}>Opening Balance</td>
                                    <td className='text-end pe-2'>{parseFloat(opening_balance).toFixed(2)}</td>
                                </tr>
                                {
                                    accountSummaryList.map((res, i) => (
                                        <tr key={i}>

                                            <td>{moment(res.date).format("DD/MM/YYYY")}</td>
                                            <td>{res.type} - {res.inv_no}</td>
                                            <td>{res.narration}</td>
                                            {res.category === "Expense" ? (
                                                <>
                                                    <td className='text-end pe-2'>{res.amount}</td>
                                                    <td></td>
                                                </>
                                            ) : (
                                                <>
                                                    <td></td>
                                                    <td className='text-end pe-2'>{res.amount}</td>
                                                </>
                                            )}
                                            {res.category === "Expense" ? (
                                                <td className='text-end pe-2'>{total = (parseFloat(total) + parseFloat(res.amount)).toFixed(2)}</td>
                                            ) : (
                                                <td className='text-end pe-2'>{total = (parseFloat(total) - parseFloat(res.amount)).toFixed(2)}</td>
                                            )}
                                        </tr>

                                    ))
                                }
                                <tr>
                                    <th colSpan={5} className="text-end text-white bg-secondary">Closing Balance</th>
                                    <th className="text-white text-end pe-2 bg-secondary">{parseFloat(total).toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className='mt-4'>
                    <ReactToPrint
                        trigger={() => <Button variant='contained' color='success' startIcon={<LocalPrintshopIcon />}>Print</Button>}
                        content={() => componentRef.current}
                    />
                </div>
            </div>
        </>
    )
}

export default ClientStatement