import React, { useState } from 'react'
import { Button, TextField } from '@mui/material'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UPDATE_PARTNER } from '../Const/ApiConst'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { instance } from '../Const/ApiHeader'

function UpdatePartnerForm(props) {

    const navigate = useNavigate()

    const [name, setName] = useState(props.obj.PartnerName)
    const [contact, setContact] = useState(props.obj.ContactNo)
    const [email, setEmail] = useState(props.obj.Email)
    const [gst, setGst] = useState(props.obj.GSTNo)
    const [state, setState] = useState(props.obj.GSTState)
    const [stateCode, setStateCode] = useState(props.obj.GSTStateCode)
    const [pointOfContact, setPOintOfConact] = useState(props.obj.pointOfContact)
    const [address, setAddress] = useState(props.obj.Address)
    const [Pincode, setPincode] = useState(props.obj.PinCode)

    const saveForm = async (e) => {
        e.preventDefault()
        const data = {
            id: props.obj._id,
            name: name,
            contact: contact,
            email: email,
            gst: gst,
            state: state,
            stateCode: stateCode,
            pointOfContact: pointOfContact,
            address: address,
            Pincode: Pincode,
            PartnerType: "Vendor"
        }

        try {
            await instance.put(
                UPDATE_PARTNER,
                data
            ).then(res => {
                navigate("/Vendors")
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <Form onSubmit={(e) => saveForm(e)} autoComplete='off'>
            <Form.Group className='form-row'>
                <Form.Group className='form-col'>
                    <strong>Basic Details</strong>
                </Form.Group>
                <Form.Group className='form-col'>
                    <TextField size="small" required label="Vendor Name" defaultValue={name} onChange={(e) => setName(e.target.value)} />
                    <TextField size="small" required label="Contact No" defaultValue={contact} onChange={(e) => setContact(e.target.value)} />
                    <TextField size="small" label="Email Address" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
            </Form.Group>
            <hr />
            <Form.Group className='form-row'>
                <Form.Group className='form-col'>
                    <strong>GST</strong>
                </Form.Group>
                <Form.Group className='form-col'>
                    <TextField size="small" label="GST No" defaultValue={gst} onChange={(e) => setGst(e.target.value)} />
                    <TextField size="small" label="State" defaultValue={state} onChange={(e) => setState(e.target.value)} />
                    <TextField size="small" label="State Code" defaultValue={stateCode} onChange={(e) => setStateCode(e.target.value)} />
                </Form.Group>
            </Form.Group>
            <hr />
            <Form.Group className='form-row'>
                <Form.Group className='form-col'>
                    <strong>Contact</strong>
                </Form.Group>
                <Form.Group className='form-col'>
                    <TextField size="small" required label="Point of Contact" defaultValue={pointOfContact} onChange={(e) => setPOintOfConact(e.target.value)} />
                    <TextField size="small" label="Address" defaultValue={address} onChange={(e) => setAddress(e.target.value)} />
                    <TextField size="small" label="Pin Code" defaultValue={Pincode} onChange={(e) => setPincode(e.target.value)} />
                </Form.Group>
            </Form.Group>
            <Form.Group className='form-row'>
                <div className='form-col'></div>
                <div className='form-col mt-4'>
                    <Button variant='contained' startIcon={<AutorenewIcon/>} type='submit' >Update</Button>
                </div>
            </Form.Group>
        </Form>
    )
}

export default UpdatePartnerForm