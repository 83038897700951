import TabContext from '@mui/lab/TabContext/TabContext';
import TabList from '@mui/lab/TabList/TabList';
import TabPanel from '@mui/lab/TabPanel/TabPanel';
import { Box, Button, Modal, Tab, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import './client.css'
import { GET_SINGLE_PARTNER, UPDATE_PARTNER } from '../../Const/ApiConst';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ClientGSTInvoice from './ClientGSTInvoice';
import ClientReceipts from './ClientReceipts';
import ClientEstimateInvoice from './ClientEstimateInvoice';
import ClientStatement from './ClientStatement';
import LoopIcon from '@mui/icons-material/Loop';
import EditIcon from '@mui/icons-material/Edit';

const partnerType = "Client"
const token = sessionStorage.getItem('wsstfaarvav')
const api_key = process.env.REACT_APP_API_KEY
const instance = axios.create({
    headers: {
        "Content-Type": 'application/json',
        'x-auth-token': token,
        'x-api-key': api_key,
        'PartnerType': partnerType
    }
})

function ViewClient() {
    const [value, setValue] = useState('1');
    const [clientName, setClientName] = useState('');
    const [contactPerson, setcontactPerson] = useState("");
    const [contactNo, setcontactNo] = useState('');
    const [email, setemail] = useState('');
    const [address, setaddress] = useState('');
    const [pincode, setpincode] = useState('');
    const [gstno, setgstno] = useState('');
    const [gstState, setgstState] = useState('');
    const [gststaeCode, setgststaeCode] = useState('');

    const params = useParams()
    const id = params.id

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const customerDetails = async () => {
        try {
            const data = {
                id: id
            }
            await instance.post(
                GET_SINGLE_PARTNER,
                data
            ).then(response => {
                response.data.data.forEach(element => {
                    setClientName(element.PartnerName)
                    setcontactPerson(element.pointOfContact)
                    setcontactNo(element.ContactNo)
                    setemail(element.Email)
                    setaddress(element.Address)
                    setpincode(element.PinCode)
                    setgstno(element.GSTNo)
                    setgstState(element.GSTState)
                    setgststaeCode(element.GSTStateCode)
                });
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        customerDetails()
        // eslint-disable-next-line
    }, [])

    const updateClient = async () => {
        try {
            const data = {
                id: params.id,
                name: clientName,
                contact: contactNo,
                email: email,
                gst: gstno,
                state: gstState,
                stateCode: gststaeCode,
                pointOfContact: contactPerson,
                address: address,
                Pincode: pincode,
                PartnerType: "Client"
            }
            await instance.put(
                UPDATE_PARTNER,
                data
            ).then(res => {
                handleClose()
            })
        } catch (error) {
            console.log(error);
        }
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <div className='page-title'>Client  <Button onClick={handleOpen} color='success' startIcon={<EditIcon />}>Edit Client</Button></div>
            <div className='cient-view-container'>
                <div style={{ padding: '10px' }}>
                    <Link to="/Clients"><Button variant='contained' color='success'>Back</Button></Link>
                </div>
                <div>
                    {/* <div className='d-flex justify-content-between text-center p-4' style={{ overflow: 'auto' }} >
                        <div className='client-cards'>
                            Invoices
                            <h1><strong>12</strong></h1>
                        </div>
                        <div className='client-cards'>
                            Receipts
                            <h1><strong>21</strong></h1>
                        </div>
                        <div className='client-cards'>
                            Due/Balance
                            <h1><strong>500</strong></h1>
                        </div>
                        <div className='client-cards'>
                            Pending Invoices
                            <h1><strong>4</strong></h1>
                        </div>
                    </div> */}
                    <main  >
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList variant='scrollable' onChange={handleChange} aria-label="lab API tabs example">

                                        <Tab label="Account Statement" value="1" />
                                        <Tab label="Estimates" value="2" />
                                        <Tab label="GST Invoices" value="3" />
                                        <Tab label="Receipts" value="4" />

                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <ClientStatement />
                                </TabPanel>
                                <TabPanel value="2">
                                    <ClientEstimateInvoice />
                                </TabPanel>
                                <TabPanel value="3">
                                    <ClientGSTInvoice />
                                </TabPanel>
                                <TabPanel value="4">
                                    <ClientReceipts />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </main>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <strong>Basic Details</strong>
                    <div >
                        <div className='m-3'>
                            <TextField
                                value={clientName}
                                label="Client Name"
                                fullWidth size='small'
                                onChange={(e) => setClientName(e.target.value)} />
                        </div>
                        <div className='m-3'>
                            <TextField
                                value={contactPerson}
                                label="Contact Person"
                                fullWidth size='small'
                                onChange={(e) => setcontactPerson(e.target.value)} />
                        </div>
                        <strong>Communication</strong>
                        <div className='m-3'>
                            <TextField
                                value={contactNo}
                                label="Contact Number"
                                fullWidth size='small'
                                onChange={(e) => setcontactNo(e.target.value)} />
                        </div>
                        <div className='m-3'>
                            <TextField
                                value={email}
                                label="Email" fullWidth
                                size='small'
                                onChange={(e) => setemail(e.target.value)}
                            />
                        </div>
                        <div className='m-3'>
                            <TextField
                                value={address}
                                label="Address" fullWidth
                                size='small'
                                onChange={(e) => setaddress(e.target.value)} />
                        </div>
                        <div className='m-3'>
                            <TextField
                                value={pincode}
                                label="PIN Code"
                                fullWidth size='small'
                                onChange={(e) => setpincode(e.target.value)} />
                        </div>
                        <strong>GST</strong>
                        <div className='m-3'>
                            <TextField
                                value={gstno}
                                label="GST NO"
                                fullWidth size='small'
                                onChange={(e) => setgstno(e.target.value)} />
                        </div>
                        <div className='m-3'>
                            <TextField
                                value={gstState}
                                label="GST State"
                                fullWidth size='small'
                                onChange={(e) => setgstState(e.target.value)} />
                        </div>
                        <div className='m-3'>
                            <TextField
                                value={gststaeCode}
                                label="GST State Code
                              " fullWidth size='small'
                                onChange={(e) => setgststaeCode(e.target.value)}
                            />
                        </div>
                        <Button
                            variant='contained'
                            color='success'
                            startIcon={<LoopIcon />}
                            onClick={() => updateClient()}>Update</Button>
                    </div>

                </Box>
            </Modal>

        </>
    )
}

export default ViewClient