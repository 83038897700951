import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { instance } from '../../Const/ApiHeader'
import { GET_ESTIMATES_VIEW, NEW_ESTIMATE_RETURN } from '../../Const/ApiConst'
import moment from 'moment/moment'
import { Table } from 'react-bootstrap'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

function NewSalesReturnEntry() {
    const params = useParams()
    const [esitmateData, setEstimateData] = useState([])
    const [custData, setCustData] = useState([])
    const [tempItemArray, setTempItemArray] = useState([])
    const [invoiceNo, setInvoiceNo] = useState()
    const [invoiceDate, setInvoiceDate] = useState()
    const [cid, setcid] = useState()
    const [narration, setNarration] = useState('')
    const [CustId, setCustId] = useState('')
    const [invTotal, setInvTotal] = useState(0)
    const Navigate = useNavigate()

    const fetchEstimateDetails = async () => {
        try {
            const data = {
                id: params.id
            }
            await instance.post(
                GET_ESTIMATES_VIEW,
                data
            ).then(res => {
                setEstimateData(res.data.data)
                setCustData(res.data.cust_data)
                setCustData(res.data.cust_data)
                setcid(res.data.cust_data[0]._id);
                setCustId(res.data.data[0].cust_id);
                setInvoiceDate(res.data.data[0].invoice_date)
                setInvoiceNo(res.data.data[0].invoice_no)
                setInvTotal(res.data.data[0].invoiceTotal + res.data.data[0].transporting_charge - res.data.data[0].discount)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchEstimateDetails()
        // eslint-disable-next-line
    }, [])

    const billingdata = () => {
        return esitmateData.map((res, i) => {
            return <div key={i} className='estimate-item-container w-100'>
                <TextField
                    size='small'
                    label="Invoice Date"
                    disabled
                    value={moment(res.invoice_date).format("DD/MM/YYYY")} />
                <TextField
                    size='small'
                    label="Invoice No"
                    disabled
                    value={res.invoice_no} />
                {res.no_of_days === 1 ? (
                    <TextField
                        size='small'
                        label="Number of Days"
                        value={res.no_of_days + " Day"} />
                ) : (
                    <TextField
                        size='small'
                        label="Invoice Date"
                        disabled
                        value={res.no_of_days + " Days"} />
                )}
                <TextField
                    size='small'
                    label="Location"
                    disabled
                    value={res.location} />
            </div>
        })
    }

    const customerdata = () => {

        return custData.map((res, i) => {
            return <div key={i} className='estimate-item-container w-100'>
                <TextField
                    size='small'
                    label="Client Name"
                    disabled
                    value={res.PartnerName} />
                <TextField
                    size='small'
                    label="GST No"
                    disabled
                    value={res.GSTNo} />
                <TextField
                    size='small'
                    label="Address"
                    disabled
                    value={res.Address} />
                <TextField
                    size='small'
                    label="Pin Code"
                    disabled
                    value={res.PinCode} />
            </div>
        })
    }

    const itemDetails = () => {
        return esitmateData.map((resp) => {
            return resp.items.map((res, i) => {
                return <tr key={i}>
                    <td>
                        <input
                            type='checkbox'
                            onChange={(e) => addToArray(e, res)} />
                    </td>
                    <td>{res.item_name}</td>
                    <td>{res.qty}</td>
                    <td>{res.rate}</td>
                    <td>{res.qty * res.rate}</td>
                </tr>
            })
        });
    }

    const addToArray = (e, res) => {
        if (e.target.checked) {
            setTempItemArray([...tempItemArray, res])
        } else {
            const updatedItems = tempItemArray.filter(item => item !== res)
            setTempItemArray(updatedItems)
        }
    }
    const saveAction = async () => {
        console.log(CustId);
        if (tempItemArray.length > 0) {
            try {
                const data = {
                    iid: params.id,
                    cid: cid,
                    idn: invoiceNo,
                    idd: invoiceDate,
                    itms: tempItemArray,
                    description: narration,
                    invType: 'estimate',
                    CustId: CustId,
                    sum: invTotal
                }
                await instance.post(
                    NEW_ESTIMATE_RETURN,
                    data
                ).then(response => {
                    Navigate("/View_Credit_Note/" + response.data.data._id)
                }).catch(err => {
                    console.log(err)
                })
            } catch (error) {
                console.log(error)
            }
        } else {

        }
    }
    return (
        <>
            <div className='page-title'>New Estimate Return</div>
            <div style={{ padding: '10px' }}><Link to='/New_Return'><Button variant='contained' color='success'>Back</Button></Link></div>
            <main>
                <div className='mb-2'>
                    <strong>Custome Details</strong>
                </div>
                {customerdata()}
            </main>
            <main>
                <div className='mb-2'>
                    <strong>Invoice Details</strong>
                </div>
                {billingdata()}
            </main>

            <main>
                <div className='mb-2'>
                    <strong>Item Details</strong>
                </div>
                <hr className='m-0 mb-2' />
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Particulars</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemDetails()}
                    </tbody>
                </Table>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    size='small'
                    label="Narration"
                    onChange={(e) => setNarration(e.target.value)}
                    className='mb-4'
                />

                <Button
                    variant='contained'
                    color='error'
                    onClick={() => saveAction()}
                    startIcon={<DoDisturbAltIcon />}>Return Items</Button>
            </main>
        </>
    )
}

export default NewSalesReturnEntry