import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import ClientDropDown from '../../Components/ClientDropDown'
import { units } from '../../Utils/Units'
import './Estimate.css'
import { MdRemoveCircleOutline } from 'react-icons/md'
import { instance } from '../../Const/ApiHeader'
import { SAVE_ESTIMATE, GET_ALL_ITEMS, GET_ESTIMATES_VIEW, UPDATE_ESTIMATE } from '../../Const/ApiConst'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DangerousIcon from '@mui/icons-material/Dangerous';

function UpdateEstimate() {
    const params = useParams()
    const [clientId, setClientId] = useState([])
    const [narration, setNarration] = useState('')
    const [tempArray, setTempArray] = useState([])
    const [unit, setUnit] = useState("Nos")
    const [location, setLocation] = useState('')
    const [item, setItem] = useState()
    const [qty, setQty] = useState()
    const [rate, setRate] = useState()
    const [noOfDays, setNoOfDays] = useState('')
    var a = 0;
    var sum = 0;
    var lineTotal = 0;
    const [iitteemm, setiitteemm] = useState([])
    const [eventDate, setEventDate] = useState('')

    const fetchEstimateDetails = async () => {
        try {
            const data = {
                id: params.id
            }
            await instance.post(
                GET_ESTIMATES_VIEW,
                data
            ).then(res => {
                console.log(res.data);
                // setEstimateData(res.data.data)
                setClientId(res.data.cust_data)
                setEventDate(res.data.data[0].eventFromDate)
                setNarration(res.data.data[0].narration)
                setTempArray(res.data.data[0].items)
                setDiscount(res.data.data[0].discount)
                setTransportationCharge(res.data.data[0].transporting_charge)
                setNoOfDays(res.data.data[0].no_of_days)
                setLocation(res.data.data[0].location)

            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchEstimateDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const loadItemList = async () => {
        try {
            await instance.get(
                GET_ALL_ITEMS
            ).then(itemResponse => {
                setiitteemm(itemResponse.data.data.map((item) => {
                    return { id: item._id, value: item.item_name }
                }))
            }).catch(err => console.log(err))
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        loadItemList()
        // eslint-disable-next-line
    }, [])


    const Navigate = useNavigate()
    const [discount, setDiscount] = useState(0)
    const [transportationCharge, setTransportationCharge] = useState(0)
    const [open, setOpen] = useState(false);
    const [validationModalError, setValidationModalError] = useState('');

    const handleOpen = (msg) => {
        setValidationModalError(msg)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);


    const unitChange = (e) => {
        setUnit(e.target.value)
    }

    const saveToTempArray = () => {

        if (noOfDays === undefined || noOfDays === '') {
            handleOpen("Please enter event duration")
        } else if (item === undefined || item === '') {
            handleOpen('Please select an Item')
        } else if (qty === undefined || qty === '') {
            handleOpen('Please enter Quatity')
        } else if (rate === undefined || rate === '') {
            handleOpen('Please enter Rate')
        } else {

            const data = {
                'item_name': item,
                'qty': qty,
                "unit": unit,
                "rate": rate,
                'noOfDays': noOfDays,
            }

            setTempArray([...tempArray, data])

            setItem('')
            setQty('')
            setRate('')
        }
    }

    const removeItemTempArray = (i) => {
        setTempArray(tempArray.filter((res, e) => e !== i))
    }

    const saveAction = async () => {


        if (tempArray.length <= 0) {
            handleOpen("No data! Please add items")
        } else if (clientId.length <= 0) {
            handleOpen("Please select Customer")
        } else if (location === undefined || location === '') {
            handleOpen("Please enter Location")
        } else {



            var cust_id = '';

            clientId.forEach(element => {
                cust_id = element._id;
            });

            const data = {
                invoiceId: params.id,
                cust_id: cust_id,
                location: location,
                no_of_days: noOfDays,
                narration: narration,
                sum: sum,
                invoiceType: 'estimate',
                invoiceData: tempArray,
                invoiceTotal: sum,
                transportingChrage: transportationCharge,
                discount: discount,
                eventDate: eventDate
            }
            try {
                await instance.post(
                    UPDATE_ESTIMATE,
                    data
                ).then(res => {
                    Navigate('/View_Estimate/' + res.data.data)
                }).catch(err => {
                    console.log(err);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 2,
    };

    const getItemdetailsAction = async (itm) => {
        setItem(itm.target.value)
    }

    return (
        <>
            <div className='page-title'>New Estimate</div>
            <div style={{ padding: '10px' }}>
                <Link to="/Estimate">
                    <Button variant='contained' color='success' >Back</Button>
                </Link>
            </div>
            <main className='d-flex gap-3 flex-wrap'>
                <div className='estimate-item-container w-100'>
                    <div className='searchbillingclient w-100'>
                        <div className='searchFilterDropdown w-100' >
                            <div style={{ width: '165px' }}>Search Client</div>
                            <div className='d-flex flex-column w-100'>
                                <ClientDropDown sendToParent={setClientId} />
                            </div>
                        </div>
                    </div>
                    <div className='w-100 ddd'>
                        <TextField
                            size='small'
                            label="No of Days"
                            className='w-100'
                            type='number'
                            value={noOfDays}
                            onChange={(e) => setNoOfDays(e.target.value)} />
                    </div>
                    <div className='w-100 ddd'>
                        <TextField
                            size='small'
                            label="Event Date"
                            className='w-100'
                            value={eventDate}
                            onChange={(e) => setEventDate(e.target.value)} />
                    </div>
                    <div className='w-100'>
                        <TextField
                            size='small'
                            label='Location'
                            value={location}
                            className='w-100'
                            onChange={(e) => setLocation(e.target.value)} />
                    </div>
                </div>
                <hr style={{ margin: '0 0', width: '100%' }} />
                <strong>Client Details</strong>
                {clientId.map((res, i) => (
                    <div key={i} className="estimate-item-container w-100">
                        <TextField
                            size='small'
                            value={res.ContactNo}
                            label="Contact No"
                            className='w-100'
                            disabled />
                        <TextField
                            size='small'
                            value={res.GSTNo}
                            label="GST No"
                            className='w-100'
                            disabled />
                        <TextField
                            size='small'
                            value={res._id}
                            label="Address"
                            className='w-100'
                            disabled />
                    </div>
                ))}
            </main>
            <main>
                <strong>Items</strong>
                <div className='estimate-item-container mt-3 mb-1'>
                    <div className='w-100'>
                        <Select
                            fullWidth
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            onChange={(e) => getItemdetailsAction(e)}
                        >
                            {iitteemm.map((res, i) => (
                                <MenuItem value={res.value} key={i}>{res.value}</MenuItem>
                            ))}

                        </Select>
                    </div>
                    <div className='w-100'>
                        <FormControl className='w-100'>
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                size='small'
                                id="demo-simple-select"
                                defaultValue={"Nos"}
                                className='w-100'
                                onChange={(e) => unitChange(e)}
                            >
                                {units.map((res, i) => (
                                    <MenuItem key={i} value={res}>{res}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='w-100'>
                        <TextField
                            size='small'
                            label="Item Qty"
                            className='w-100'
                            type='number'
                            value={qty}
                            onChange={(e) => setQty(e.target.value)} />
                    </div>
                    <div className='w-100'>
                        <TextField
                            size='small'
                            label="Item Rate"
                            className='w-100'
                            type='number'
                            value={rate}
                            onChange={(e) => setRate(e.target.value)} />
                    </div>

                    <Button variant='contained' onClick={() => saveToTempArray()}>Add</Button>
                </div>
            </main>
            <main>
                <strong>Preview</strong>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Sl No</th>
                            <th>Particulars</th>
                            <th>Unit</th>
                            <th>Qty</th>
                            {/* <th>No of Days</th> */}
                            <th>Rate</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempArray.map((res, i) => (
                            <tr key={i}>
                                <td>{a = a + 1}</td>
                                <td>{res.item_name}</td>
                                <td>{res.unit}</td>
                                <td>{res.qty}</td>
                                {/* <td>{res.noOfDays}</td> */}
                                <td>{res.rate}</td>
                                <th>{lineTotal = res.qty * res.rate}</th>
                                <th><MdRemoveCircleOutline className='text-danger' style={{ fontSize: '24px' }} onClick={() => removeItemTempArray(i)} /><div style={{ display: 'none' }}>{sum = sum + parseFloat(lineTotal)}</div></th>

                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>{sum}</th>
                            <th></th>
                        </tr>
                    </tfoot>
                </Table>
                <TextField
                    size='small'
                    label="Discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)} />
                <TextField
                    size='small'
                    label="Transportation Charge"
                    value={transportationCharge}
                    onChange={(e) => setTransportationCharge(e.target.value)} />
                <TextField
                    size='small'
                    value={sum - parseFloat(discount) + parseFloat(transportationCharge)}
                    label="Grand Total" />
                <div className='mb-4 mt-2'>
                    <TextField label="Narration"
                        rows={5}
                        multiline
                        fullWidth
                        value={narration}
                        onChange={(e) => setNarration(e.target.value)} />
                </div>
                <Button
                    variant='contained'
                    startIcon={<SaveIcon />}
                    endIcon={<LocalPrintshopIcon />}
                    onClick={() => { saveAction() }}
                    className="d-flex align-items-center gap-2">Save & Print</Button>
            </main>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='text-danger'>
                    <DangerousIcon className='text-danger me-2' />
                    <strong>Error : </strong>
                    {validationModalError}
                </Box>
            </Modal>
        </>
    )
}

export default UpdateEstimate