import { Box, Button, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import FormControl from '@mui/material/FormControl';
import { GET_EXPENSE, NEW_EXPENSE } from '../../Const/ApiConst';
import { instance } from '../../Const/ApiHeader'
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { VARIABLE_EXPENSE_TYPES } from '../../Variables/ExpenseList';

function Expense() {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 2,
    };

    const textEnd = {
        display: 'flex',
        justifyContent: 'end'
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [type, setType] = useState('');
    const [name, setName] = useState()
    const [reference, setReference] = useState()
    const [amount, setAmount] = useState()

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const saveExpenseAction = async (e) => {
        e.preventDefault()
        try {
            const data = {
                type: type,
                name: name,
                reference: reference,
                amount: amount
            }
            await instance.post(
                NEW_EXPENSE,
                data
            ).then(res => {
                console.log(res);
                handleClose()
                fetchUsers(1)
            }).catth(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            name: 'Date',
            selector: row => moment(row.created_at).format("DD/MM/YYYY"),
        },
        {
            name: 'Name',
            selector: row => row.expense_name,
        },
        {
            name: 'Type',
            selector: row => row.expense_type,
        },
        {
            name: 'Reference',
            selector: row => row.reference,
        },
        {
            name: 'Amount',
            selector: row => row.amount.toFixed(2),
            style: textEnd
        }
    ];

    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        const response = await instance.post(
            GET_EXPENSE,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_EXPENSE,
            data
        )

        console.log(response.data.data);
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='page-title'>Expense</div>
            <div style={{ padding: '10px' }}>
                <Button
                    variant='contained'
                    color='success'
                    onClick={handleOpen}>New Expense</Button>
            </div>
            <main>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />

            </main>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h4><strong>New Expense</strong></h4>
                    <hr />
                    <Form className='d-flex flex-column gap-3' onSubmit={(e) => saveExpenseAction(e)}>
                        <FormControl fullWidth>
                            <InputLabel size='small'>Expense Type</InputLabel>
                            <Select
                                size='small'
                                id="demo-simple-select"
                                value={type}
                                label="Expense Type"
                                onChange={handleChange}
                            >
                                {VARIABLE_EXPENSE_TYPES.map((xrc, x) => (
                                    <MenuItem value={xrc} key={x}>{xrc}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            size="small"
                            label="Name"
                            onChange={(e) => setName(e.target.value)}
                            fullWidth 
                            required/>
                        <TextField
                            size="small"
                            label="Reference"
                            onChange={(e) => setReference(e.target.value)}
                            fullWidth 
                            required/>
                        <TextField
                            size="small"
                            label="Amount"
                            type='number'
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            required />
                        <Button
                            type='submit'
                            variant='contained'
                            color='success'
                            fullWidth
                            startIcon={<SaveIcon />} >Save</Button>
                    </Form>
                </Box>
            </Modal>
        </>
    )
}

export default Expense