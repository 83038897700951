import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { instance } from '../../Const/ApiHeader'
import { GET_ESTIMATE_INVOICE_DATA } from '../../Const/ApiConst';
import DataTable from 'react-data-table-component';
import moment from 'moment'
import { Button } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { Form } from 'react-bootstrap';

function EstimateList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const textEnd = {
        display: 'flex',
        justifyContent: 'end'
    }
    const fullWidrhRow = {
        width: '500px'
    }
    const columns = [
        {
            name: 'Estimate Date',
            selector: row => moment(row.invoice_date).format("DD/MM/YYYY"),
        },
        {
            name: 'Estimate No',
            selector: row => row.invoice_no,
            style: fullWidrhRow
        },
        {
            name: 'Client',
            selector: row => row.partner[0].PartnerName,
        },
        {
            name: 'Location',
            selector: row => row.location
        },
        {
            name: 'Event Duration',
            selector: row => row.no_of_days + " Days",
        },
        {
            name: 'Amount',
            selector: row => row.invoiceTotal.toFixed(2),
            style: textEnd
        },
        {
            name: '',
            selector: row => <Link to={'/New_Estimate_Return/' + row._id}><Button variant='contained' color='warning' size='small' startIcon={<PreviewIcon />}>Return</Button></Link>
        }
    ];
    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        const response = await instance.post(
            GET_ESTIMATE_INVOICE_DATA,
            data
        )
        const fileredData = response.data.data.filter(item => item.status === "Invoiced")
        setData(fileredData);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_ESTIMATE_INVOICE_DATA,
            data
        )
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchAction = async (e, page) => {
        setLoading(true);
        const data = {
            page: 1,
            per_page: perPage,
            searchKey: e.target.value
        }
        const response = await instance.post(
            GET_ESTIMATE_INVOICE_DATA,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }







    return (
        <>
            <div className='col-12 col-sm-3'>
                <Form.Control
                    type="search"
                    className='mb-2'
                    placeholder='Search Invoice No...'
                    onChange={(e) => searchAction(e)} />
            </div>
            <DataTable
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </>
    )
}

export default EstimateList