import React, { useEffect } from 'react'
import income from '../../images/income.png'
import expense from '../../images/expense.png'
import savings from '../../images/savings.png'
import { Card } from '@mui/material'
import { instance } from '../../Const/ApiHeader'
import { GET_DASHBOARD_COUNTRS } from '../../Const/ApiConst'
import { useState } from 'react'

function CountBoxs() {
    const [totalReceipt, setTotalReceipts] = useState()
    const [expeseTotal, setExpenseTotal] = useState()
    const [profit, setProfit] = useState()
    const cardContainer = {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        gap: "20px"
    }
    const cardImage = {
        width: '100px',
        zIndex: 1
    }
    const card = {
        // minWidth: '260px',
        position: 'relative',
        height:'150px'
    }

    const fetchData = async () => {
        try {
            await instance.post(
                GET_DASHBOARD_COUNTRS
            ).then(response => {
                setTotalReceipts('₹ ' + response.data.receipts.toFixed(2))
                setExpenseTotal('₹ ' + response.data.expense.toFixed(2))
                var profit = response.data.receipts - response.data.expense
                setProfit('₹ ' + profit.toFixed(2))
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    return (
        <div style={cardContainer} className=''>
            <div className='row w-100'>
                <div className='col-12 col-sm-12 col-md-4 p-3' >
                    <Card style={card} className='d-flex align-items-center justify-content-between'>
                        <div className='test'></div>
                        <div className='text-center' style={{ zIndex: 2 }}>
                            <span>Income</span>
                            <h4><strong>{totalReceipt}</strong></h4>
                        </div>
                        <img src={expense} style={cardImage} alt='' />
                    </Card>
                </div>
                <div className='col-12 col-sm-12 col-md-4 p-3' >
                    <Card className='  d-flex align-items-center justify-content-between p-3' style={card}>
                        <div className='test'></div>
                        <div className='text-center' style={{ zIndex: 2 }}>
                            <span>Expense</span>
                            <h4><strong>{expeseTotal}</strong></h4>
                        </div>
                        <img src={income} style={cardImage} alt='' />
                    </Card>
                </div>
                <div className='col-12 col-sm-12 col-md-4 p-3' >
                    <Card className='d-flex align-items-center justify-content-between p-3' style={card}>
                        <div className='test'></div>
                        <div className='text-center' style={{ zIndex: 2 }}>
                            <span>Profit</span>
                            <h4><strong>{profit}</strong></h4>
                        </div>
                        <img src={savings} style={cardImage} alt='' />
                    </Card>
                </div>
                {/* <Card className='col-12 col-sm-12 col-md-3 d-flex align-items-center justify-content-between p-3' style={card}>
                    <div className='text-center'>
                        <span>Pendig Invoice</span>
                        <h4><strong>₹ 10,00,000.00</strong></h4>
                    </div>
                    <img src={savings} style={cardImage} alt='' />
                </Card> */}
                <br />

            </div>

        </div>
    )
}





export default CountBoxs