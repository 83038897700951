import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, TextField, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment'
import { instance } from '../../Const/ApiHeader';
import { GET_GST_CLIENT_FILTER, GET_GST_INVOICE_DATA } from '../../Const/ApiConst';
import DataTable from 'react-data-table-component';
import PreviewIcon from '@mui/icons-material/Preview';
import { Form } from 'react-bootstrap';
import { GET_PARTNER_LIST } from '../../Const/ApiConst';
import axios from 'axios';


const partnerType = "Client"
const api_key = process.env.REACT_APP_API_KEY
const token = sessionStorage.getItem('wsstfaarvav')

const clientinstance = axios.create({
    headers: {
        "Content-Type": 'application/json',
        'x-auth-token': token,
        'x-api-key': api_key,
        'PartnerType': partnerType
    }
})

function Billing() {

    const Navigate = useNavigate()

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [clientListForFilter, setClientListForFilter] = useState([])
    const [selectedClient, setSelectedClient] = useState('')

    const textEnd = {
        display: 'flex',
        justifyContent: 'end',
        width: '10px'
    }

    const fullWidthRow = {
        width: '500px'
    }
    const columns = [

        {
            name: 'Invoice Date',
            selector: row => moment(row.invoice_date).format("DD/MM/YYYY"),
            reorder: true,
            maxWidth: "115px"
        },
        {
            name: 'Invoice Number',
            selector: row => row.invoice_no,
            reorder: true,
            maxWidth: "150px"
        },
        {
            name: 'Client',
            selector: row => <Tooltip title={row.partner[0].PartnerName}><span>{row.partner[0].PartnerName}</span></Tooltip>,
            style: fullWidthRow,
            reorder: true,
            maxWidth: '150px'
        },
        {
            name: 'Location',
            selector: row => <Tooltip title={row.location}><span>{row.location}</span></Tooltip>,
            style: fullWidthRow,
            reorder: true,
            maxWidth: '200px'
        },
        // {
        //     name: 'Event Duration',
        //     selector: row => row.no_of_days + ' Days',
        //     reorder: true,
        // },
        {
            name: 'Amount',
            selector: row => row.invoiceTotal.toFixed(2),
            style: textEnd,
            reorder: true,
            right: true,
            maxWidth: '120px'
        },

        {
            name: '',
            selector: row => <Link to={"/Edit_Invoice/" + row._id}><Button size='small' variant='contained' color='info' startIcon={<PreviewIcon />}>Edit</Button></Link>,
            reorder: true,
            maxWidth: '110px'
        }
    ];

    const previewInvoice = (e) => {
        // Navigate("/View_Invoice/" + e)
        const url = "/View_Invoice/" + e;
        window.open(url, "_blank");
    }

    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage,
            selectedClient: selectedClient
        }
        const response = await instance.post(
            GET_GST_INVOICE_DATA,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage,
            selectedClient: selectedClient
        }
        const response = await instance.post(
            GET_GST_INVOICE_DATA,
            data
        )

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const loadClientList = () => {
        clientinstance.get(
            GET_PARTNER_LIST
        ).then((res) => {
            setClientListForFilter(res.data.data)
        })
    }
    useEffect(() => {
        loadClientList()
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const searchAction = async (e, page) => {
        setLoading(true);
        const data = {
            page: 1,
            per_page: perPage,
            searchKey: e.target.value,
            selectedClient: selectedClient
        }
        const response = await instance.post(
            GET_GST_INVOICE_DATA,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }


    const [fromDate, setFromDate] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"))
    const [partnerId, setPartnerId] = useState("")


    const vendorFilterAction = (page) => {
        const data = {
            page: 1,
            per_page: perPage,
            id: partnerId,
            fromDate: fromDate,
            toDate: toDate
        }

        instance.post(
            GET_GST_CLIENT_FILTER,
            data
        ).then(response => {
            setData(response.data.data);
            setTotalRows(100);
            setLoading(false);
        })
    }



    const totalSum = data.reduce((sum, data) => sum + data.invoiceTotal, 0);


    return (
        <>
            <div className='page-title'>Billing</div>


            <div style={{ padding: '10px' }}>
                <Link to="/New_Billing" ><Button variant='contained' color='success' startIcon={<AddIcon />}>New Billing</Button></Link>
            </div>
            <main>
                <div className='row'>

                    <div className='col-12 col-sm-3'>
                        <Form.Control
                            type="search"
                            className='mb-2'
                            placeholder='Search Invoice No...'
                            onChange={(e) => searchAction(e)} />
                    </div>
                    <div className='col-12 col-sm-9 d-flex gap-2'>

                        <select
                            className='form-control'
                            onChange={(e) => setPartnerId(e.target.value)}
                        >
                            <option value="ALL">All</option>
                            {clientListForFilter.map((res, i) => (
                                <option value={res._id} key={i}>{res.PartnerName}</option>
                            ))}
                        </select>
                        <TextField
                            fullWidth
                            size='small'
                            label="From date"
                            defaultValue={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            type='date' />
                        <TextField
                            fullWidth
                            size='small'
                            label="To date"
                            defaultValue={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            type='date' />
                        <Button
                            variant='contained'
                            onClick={(e) => vendorFilterAction(e)}
                        >Search</Button>
                    </div>

                </div>
                <div className='btn btn-primary d-flex gap-2 col-4 mt-4'>
                    <div>Grand Total</div>
                    <strong>{parseFloat(totalSum).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    // pagination
                    // paginationServer
                    // paginationTotalRows={totalRows}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    onRowClicked={(data) => previewInvoice(data._id)}
                />
            </main>


        </>
    )
}

export default Billing