import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Tab } from '@mui/material'
import React from 'react'
import EstimateList from './EstimateList';
import GSTList from './GSTList';
import { Link } from 'react-router-dom';

function NewSalesReturn() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className='page-title'>Sales Return</div>
            <div style={{ padding: '10px' }}>
                <Link to='/Sales_Return'>
                    <Button variant='contained' color='success'>Back</Button>
                </Link>
            </div>
            <main>
                <TabContext value={value}>

                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        <Tab label="Estimates" value="1" />
                        <Tab label="GST Invoices" value="2" />
                    </TabList>

                    <TabPanel value="1"><EstimateList /></TabPanel>
                    <TabPanel value="2"><GSTList /></TabPanel>
                </TabContext>
            </main>
        </>
    )
}

export default NewSalesReturn