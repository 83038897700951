import React from 'react'

function Topbar() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    fontWeight:'600'
  }
  return (
    <nav style={style}>EMS</nav>
  )
}

export default Topbar