import { Box, Button, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { GET_ITEMS, NEW_ITEM, UPDATE_ITEMS } from '../../Const/ApiConst';
import DataTable from 'react-data-table-component';
import { instance } from '../../Const/ApiHeader';
import { LoadingButton } from '@mui/lab';

function Items() {

    const [itemList, setItemlist] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);


    const [itemName, setItemName] = useState()
    const [hsn, setHSN] = useState(998596)
    const [tax, setTax] = useState(18)

    const [saveLoader, setSaveLoader] = useState(false)
    const [updateLoader, setUpdateLoader] = useState(false)

    const saveItem = async (e) => {
        e.preventDefault()
        setSaveLoader(true)
        const data = {
            item_name: itemName,
            hsn: hsn,
            tax: tax
        }
        try {
            await instance.post(
                NEW_ITEM,
                data
            ).then(res => {
                LoadItems()
                setOpen(false)
                // window.location.reload()
                setSaveLoader(false)
            }).catch(err => {
                console.log(err)
                setSaveLoader(false)
            })
        } catch (error) {
            console.log(error);
            setSaveLoader(false)
        }
    }



    const LoadItems = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        const response = await instance.post(
            GET_ITEMS,
            data
        )
        setItemlist(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    }

    useEffect(() => {
        LoadItems()
        // eslint-disable-next-line
    }, [])

    const columns = [
        {
            name: 'Item Name',
            selector: row => row.item_name,
        },
        {
            name: 'HSN',
            selector: row => row.hsn,
        },
        {
            name: 'Tax',
            selector: row => row.tax + '%',
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: '',
            selector: row => <Button size='small' variant='contained' onClick={() => handleUpdateOpen(row)}>Edit</Button>
        }
    ];

    const handlePageChange = page => {
        LoadItems(page);
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_ITEMS,
            data
        )

        console.log(response.data.data);
        setItemlist(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };




    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [currentItemId, setCurrentItemId] = useState("")

    const [updateOpen, setupdateOpen] = React.useState(false);
    const handleUpdateOpen = (row) => {
        setupdateOpen(true)
        setItemName(row.item_name)
        setCurrentItemId(row._id)
    };
    const updatehandleClose = () => setupdateOpen(false);


    const updateItemAction = async (e) => {
        e.preventDefault()
        setUpdateLoader(true)

        const data = {
            id: currentItemId,
            item_name: itemName,
            hsn: hsn,
            tax: tax
        }
        try {
            await instance.post(
                UPDATE_ITEMS,
                data
            ).then(res => {
                LoadItems()
                setUpdateLoader(false)
                // window.location.reload()
                setupdateOpen(false)
            }).catch(err => {
                console.log(err)
                setUpdateLoader(false)
                setupdateOpen(false)
            })
        } catch (error) {
            console.log(error);
            setUpdateLoader(false)
            setupdateOpen(false)
        }
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 2,
    };

    return (
        <>
            <div className='page-title'>Items</div>
            <div style={{ padding: '10px' }}>
                <Button variant="contained" color='success' onClick={handleOpen}>Add Item</Button>

                {/* <div className='mt-4 d-flex flex-wrap w-100'>
                    <FormControl >
                        <TextField size='small' label="Search Item..." />
                    </FormControl>
                </div> */}
            </div>

            <main>
                <DataTable
                    columns={columns}
                    data={itemList}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </main>


            {/* Add modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Form onSubmit={(e) => saveItem(e)} autoComplete='off' className='d-flex flex-column gap-4' >
                        <strong>Add New Item</strong>
                        <TextField
                            size="small"
                            label='Item Name'
                            className='w-100'
                            onChange={(e) => setItemName(e.target.value)}
                            required />
                        <TextField
                            size="small"
                            label='HSN Code'
                            className='w-100'
                            defaultValue={hsn}
                            onChange={(e) => setHSN(e.target.value)}
                            required />
                        <TextField
                            size="small"
                            label='Tax %' className
                            ='w-100'
                            defaultValue={tax}
                            onChange={(e) => setTax(e.target.value)}
                            required />

                        <LoadingButton
                            loading={saveLoader}
                            variant='contained'
                            color='warning'
                            type='submit'>Save</LoadingButton>

                    </Form>
                </Box>
            </Modal>


            {/* Update Modal */}
            <Modal
                open={updateOpen}
                onClose={updatehandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Form onSubmit={(e) => updateItemAction(e)} autoComplete='off' className='d-flex flex-column gap-4' >
                        <strong>Add New Item</strong>
                        <TextField
                            size="small"
                            label='Item Name'
                            className='w-100'
                            defaultValue={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            required />
                        <TextField
                            size="small"
                            label='HSN Code'
                            className='w-100'
                            defaultValue={hsn}
                            onChange={(e) => setHSN(e.target.value)}
                            required />
                        <TextField
                            size="small"
                            label='Tax %' className
                            ='w-100'
                            defaultValue={tax}
                            onChange={(e) => setTax(e.target.value)}
                            required />

                        <LoadingButton
                            loading={updateLoader}
                            variant='contained'
                            color='warning'
                            type='submit'>Save</LoadingButton>

                    </Form>
                </Box>
            </Modal>

        </>
    )
}

export default Items