import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GSTTable from './GSTTable';
import EstimateTable from './EstimateTable';
import { Button } from '@mui/material';

function Receipts() {
    const [value, setValue] = useState('1');

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className='page-title'>Receipts</div>
            <main>
                <Button variant='contained'>Add New Receipt</Button>
            </main>
            <main>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="GST Invoices" value="1" />
                                <Tab label="Estimates" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <GSTTable />
                        </TabPanel>
                        <TabPanel value="2">
                            <EstimateTable />
                        </TabPanel>
                    </TabContext>
                </Box>
            </main>
        </>
    )
}

export default Receipts