import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button } from '@mui/material';
import { instance } from '../../Const/ApiHeader';
import { GET_GST_INVOICE_DATA } from '../../Const/ApiConst';
import DataTable from 'react-data-table-component';
import PreviewIcon from '@mui/icons-material/Preview';
import { Link } from 'react-router-dom';


function GSTTable() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            name: 'Invoice Date',
            selector: row => moment(row.invoice_date).format("DD/MM/YYYY"),
        },
        {
            name: 'Invoice Number',
            selector: row => row.invoice_no,
        },
        {
            name: 'Location',
            selector: row => row.location,
        },
        {
            name: 'Event Duration',
            selector: row => row.no_of_days + ' Days',
        },
        {
            name: 'Amount',
            selector: row => row.invoiceTotal,
        },
        {
            name: '',
            selector: row => <Link to={"/New_Receipt/" + row._id}><Button size='small' variant='contained' startIcon={<PreviewIcon />}>New Receipt</Button></Link>
        }
    ];


    const fetchUsers = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        const response = await instance.post(
            GET_GST_INVOICE_DATA,
            data
        )
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_GST_INVOICE_DATA,
            data
        )

        console.log(response.data.data);
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };


    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
        />
    )
}

export default GSTTable