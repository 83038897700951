import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { GET_ESTIMATE_REPORT } from '../../Const/ApiConst'
import { instance } from '../../Const/ApiHeader'
import moment from 'moment'
import { utils, writeFile } from 'xlsx'

function EstimateComponent() {
    const [estimateData, setEstimateData] = useState([])
    const [fromDate, setFromDate] = useState()
    const [toDate, setTodate] = useState()
    const [estimateExcelData, setestimateExcelData] = useState([])

    const loadData = async () => {

        var xdata = []

        if (fromDate === undefined || toDate === undefined) {

        } else {

            try {
                const data = {
                    fromDate: fromDate,
                    toDate: toDate,
                }
                await instance.post(
                    GET_ESTIMATE_REPORT,
                    data
                ).then(res => {

                    setEstimateData(res.data.data)
                    console.log(res.data.data[0].partner[0].PartnerName);

                    for (let index = 0; index < res.data.data.length; index++) {
                        xdata.push({
                            invoice_date: moment(res.data.data[index].invoice_date).format("DD/MM/YYYY"),
                            clientname: res.data.data[index].partner[0].PartnerName,
                            invoice_no: res.data.data[index].invoice_no,
                            discount: res.data.data[index].discount,
                            transporting_charge: res.data.data[index].transporting_charge,
                            invoiceTotal: res.data.data[index].invoiceTotal,
                            narration: res.data.data[index].narration,
                            payment_received: res.data.data[index].payment_received
                        })
                    }

                    setestimateExcelData(xdata)
                }).catch(err => console.log(err))

            } catch (error) {
                console.log(error);
            }
        }
    }

    const tableData = () => {
        return estimateData.map((res, i) => {
            return <tr key={i}>
                <td>{moment(res.invoice_date).format("DD/MM/YYYY")}</td>
                <td>{res.partner[0].PartnerName}</td>
                <td>{res.invoice_no}</td>
                <td className='text-end'>{res.discount.toFixed(2)}</td>
                <td className='text-end'>{res.transporting_charge.toFixed(2)}</td>
                <td className='text-end'>{(parseFloat(res.invoiceTotal) - parseFloat(res.discount) + parseFloat(res.transporting_charge)).toFixed(2)}</td>
                <td className='text-end'>{parseFloat(res.payment_received).toFixed(2)}</td>
            </tr>
        })
    }


    const handleExport = () => {
        const headings = [[
            'Date',
            'Client Name',
            'Invoice No',
            'Discount',
            'Transporting Charge',
            'Total',
            'Narration',
            'Payment Collected',
        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, estimateExcelData, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Estimate Report.xlsx');
    }


    return (
        <div>
            <div className='d-flex gap-3'>
                <div>
                    <div>From Date</div>
                    <Form.Control type='date' onChange={(e) => setFromDate(e.target.value)} />
                </div>
                <div>
                    <div>To Date</div>
                    <Form.Control type='date' onChange={(e) => setTodate(e.target.value)} />
                </div>
                <div>
                    <div>&nbsp;</div>
                    <Button variant='contained' onClick={() => loadData()}>Search</Button>
                </div>
            </div>

            <div className='mt-2'>
                <div className='d-flex justify-content-end'>
                    <Button variant='contained' onClick={() => handleExport()}>Download</Button>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Client Name</th>
                            <th>Invoice No</th>
                            <th>Discount</th>
                            <th>Transporting Charge</th>
                            <th>Total</th>
                            <th>Payment Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData()}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default EstimateComponent