import React, { useEffect, useState } from 'react'
import { instance } from '../../Const/ApiHeader'
import { GET_ESTIMATE_DATA, GET_EXPENSE_DATA, GET_GST_DATA, GET_INCOME_DATA } from '../../Const/ApiConst'
import moment from 'moment'
import { FaFileInvoiceDollar } from 'react-icons/fa'


function Last5Expense() {

  const [gstData, setGstata] = useState([])
  const [estimateDate, setEstimateData] = useState([])
  const [incomeData, setIncomeData] = useState([])
  const [expenseData, setExpenseData] = useState([])

  const loadGSTData = async () => {
    try {
      await instance.get(
        GET_GST_DATA
      )
        .then(response => {
          setGstata(response.data.data)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const loadEstimateData = async () => {
    try {
      await instance.get(
        GET_ESTIMATE_DATA
      )
        .then(response => {
          setEstimateData(response.data.data)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }
  const loadIncomeData = async () => {
    try {
      await instance.get(
        GET_INCOME_DATA
      )
        .then(response => {
          setIncomeData(response.data.data)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }
  const loadExpenseData = async () => {
    try {
      await instance.get(
        GET_EXPENSE_DATA
      )
        .then(response => {
          setExpenseData(response.data.data)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    loadGSTData()
    loadEstimateData()
    loadIncomeData()
    loadExpenseData()
  }, [])

  const gstTableData = () => {
    return gstData.map((res, i) => {
      return <tr key={i}>
        <td><FaFileInvoiceDollar /></td>
        <td>{moment(res.invoice_date).format("DD/MM/YYYY")}</td>
        <td style={{ color: "#ff9730" }}>{res.invoice_no}</td>
        <td style={{ color: "#00cc9a" }} className='text-end'>{parseFloat(res.invoiceTotal) + parseFloat(res.transporting_charge) - parseFloat(res.discount)}</td>
      </tr>
    })
  }

  const estimateTableData = () => {
    return estimateDate.map((res, i) => {
      return <tr key={i}>
        <td><FaFileInvoiceDollar /></td>
        <td>{moment(res.invoice_date).format("DD/MM/YYYY")}</td>
        <td style={{ color: "#ff9730" }}>{res.invoice_no}</td>
        <td style={{ color: "#00cc9a" }} className='text-end'>{parseFloat(res.invoiceTotal) + parseFloat(res.transporting_charge) - parseFloat(res.discount)}</td>
      </tr>
    })
  }

  const incomeTableData = () => {
    return incomeData.map((res, i) => {
      return <tr key={i}>
        <td><FaFileInvoiceDollar /></td>
        <td>{moment(res.invoice_date).format("DD/MM/YYYY")}</td>
        <td style={{ color: "#ff9730" }}>{res.receipt_number}</td>
        <td style={{ color: "#ff9730" }}>{res.mode_of_payment}</td>
        <td style={{ color: "#00cc9a" }} className='text-end'>{res.payment_received}</td>
      </tr>
    })
  }
  const expenseTableData = () => {
    return expenseData.map((res, i) => {
      return <tr key={i}>
        <td><FaFileInvoiceDollar /></td>
        <td>{moment(res.created_at).format("DD/MM/YYYY")}</td>
        <td style={{ color: "#ff9730" }}>{res.expense_name}</td>
        <td style={{ color: "#ff9730" }}>{res.expense_type}</td>
        <td style={{ color: "#00cc9a" }} className='text-end'>{res.amount}</td>
      </tr>
    })
  }

  return (
    <div className='dash-data-table-row'>
      <main className='dash-table-container'>
        <strong>Last 5 GST Invoices</strong>
        <p></p>
        <table className='dash-table'>
          <thead>
            <tr >
              <th ></th>
              <th >Date</th>
              <th >Invoice No</th>
              <th className='text-end'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {gstTableData()}
          </tbody>
        </table>
      </main>
      <main className='dash-table-container'>
        <strong>Last 5 Estimates</strong>
        <p></p>
        <table className='dash-table'>
          <thead>
            <tr >
              <th ></th>
              <th >Date</th>
              <th >Invoice No</th>
              <th className='text-end'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {estimateTableData()}
          </tbody>
        </table>
      </main>
      <main className='dash-table-container'>
        <strong>Last 5 Income</strong>
        <p></p>
        <table className='dash-table'>
          <thead>
            <tr >
              <th ></th>
              <th >Date</th>
              <th >Invoice No</th>
              <th >Payment mode</th>
              <th className='text-end'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {incomeTableData()}
          </tbody>
        </table>
      </main>
      <main className='dash-table-container'>
        <strong>Last 5 Expenses</strong>
        <p></p>
        <table className='dash-table'>
          <thead>
            <tr >
              <th ></th>
              <th >Date</th>
              <th >Whom</th>
              <th >Type</th>
              <th className='text-end'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {expenseTableData()}
          </tbody>
        </table>
      </main>
    </div>
  )
}

export default Last5Expense