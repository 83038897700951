import React, { useEffect, useRef, useState } from 'react'
import { COMPANY_ADDRESS, COMPANY_CONTACT_NUMBER, COMPANY_NAME } from '../../Const/AppConfig'
import { Link, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ReactToPrint from 'react-to-print';
import { instance } from '../../Const/ApiHeader';
import { View_SINGLE_ESTIMATE_RETURN } from '../../Const/ApiConst';
import moment from 'moment';

function ViewSalesReturn() {
  const componentRef = useRef();
  const [returnData, setReturnData] = useState([])
  const params = useParams()

  var sum = 0;

  const borderbox = {
    border: '1px solid black',
    padding: '10px'
  }

  const fetchData = () => {
    const data = {
      invNo: params.id
    }
    instance.post(
      View_SINGLE_ESTIMATE_RETURN,
      data
    )
      .then(response => {
        setReturnData(response.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])


  return (
    <>
      <div className='page-title'>View Credit Note</div>
      <div style={{ padding: '10px' }}><Link to="/Sales_Return"><Button variant='contained' color='success'>Back</Button></Link></div>
      <main>
        {returnData.map((res, i) => (
          <div className='p-4' ref={componentRef} key={i}>
            <div className='text-center'>
              <h3><strong>Credit Note</strong></h3>
              <strong>(See Rule 59)</strong>
            </div>
            {res.partner.map((resss, iii) => (
              <>
                <div className='d-flex justify-content-between' key={iii}>
                  <div>No : {res.estimate_return_id}</div>
                  <table>
                    <tr>
                      <td>Date</td>
                      <td>: {moment(res.return_date).format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr>
                      <td>GST No </td>
                      <td> : {resss.GSTNo}</td>
                    </tr>
                  </table>
                </div>
                <div className='row pb-4 pt-4'>
                  <div className='col-5'>
                    From,
                    <div>
                      {COMPANY_NAME}<br />
                      {COMPANY_ADDRESS}<br />
                      Contact No: {COMPANY_CONTACT_NUMBER}
                    </div>
                  </div>
                  <div className='col-2'></div>
                  <div className='col-5'>
                    To,
                    <div>
                      {resss.PartnerName}<br />
                      {resss.Address}<br />
                      PIN - {resss.PinCode}<br />
                      Contact No - {resss.ContactNo}<br />
                    </div>
                  </div>
                </div>
              </>
            ))}

            <p>
              This is to certify that we have debited your account with value of service with taxes
              Related thereto as specified here under and that similar document has not be issued before in respect of same
              Transactions.
            </p>
            <table className='w-100'>
              <thead>
                <tr>
                  <th style={borderbox}>SlNo</th>
                  <th style={borderbox}>Particulars Of  Credit Note</th>
                  <th style={borderbox}>Description of goods</th>
                  <th style={borderbox}>Quantity</th>
                  <th style={borderbox}>Amount</th>
                  <th style={borderbox}>RelatedTax</th>
                  <th style={borderbox}>Total</th>
                </tr>
              </thead>
              <tbody>

                {res.invoiceType === 'gst_bill' &&
                  res.items.map((itemres, itemi) =>
                  (<tr key={itemi}>
                    <td style={borderbox} className='text-center'>{itemi + 1}</td>
                    <td style={borderbox}>Sales Return</td>
                    <td style={borderbox}>{itemres.item_name}</td>
                    <td style={borderbox} className='text-center'>{itemres.qty} {itemres.unit}</td>
                    <td style={borderbox} className='text-end'>{parseFloat(itemres.rate).toFixed(2)}</td>
                    <td style={borderbox} className='text-end'>{((itemres.qty * itemres.rate) * itemres.tax / 100).toFixed(2)}</td>
                    <td style={borderbox} className='text-end'>{(itemres.qty * itemres.rate + (itemres.qty * itemres.rate) * itemres.tax / 100).toFixed(2)}<div style={{ display: 'none' }}>{sum = sum + itemres.qty * itemres.rate + (itemres.qty * itemres.rate) * itemres.tax / 100}</div></td>
                  </tr>))
                }
                {res.invoiceType === 'estimate' &&
                  res.items.map((itemres, itemi) =>
                  (<tr key={itemi}>
                    <td style={borderbox} className='text-center'>{itemi + 1}</td>
                    <td style={borderbox}>Sales Return</td>
                    <td style={borderbox}>{itemres.item_name}</td>
                    <td style={borderbox} className='text-center'>{itemres.qty} {itemres.unit}</td>
                    <td style={borderbox} className='text-end'>{parseFloat(itemres.rate).toFixed(2)}</td>
                    <td style={borderbox} className='text-center'>N/A</td>
                    <td style={borderbox} className='text-end'>{(itemres.qty * itemres.rate).toFixed(2)}<div style={{ display: 'none' }}>{sum = sum + itemres.qty * itemres.rate}</div></td>
                  </tr>))
                }

              </tbody>
            </table>
            <div className='pt-3 pb-3'>
              Narration : {res.description}
            </div>
            <div className='d-flex justify-content-end'>
              <div style={{ fontSize: '20px' }}><strong>Total : {sum.toFixed(2)}</strong></div>
            </div>
          </div>

        ))}

        <div>
          <ReactToPrint
            trigger={() => <Button variant='contained' startIcon={<LocalPrintshopIcon />}>Print</Button>}
            content={() => componentRef.current}
          />
        </div>
      </main>
    </>
  )
}

export default ViewSalesReturn



