// const config = require('../../package.json').projectConfig
// const BACKEND_BASE_URL = config.backendApiBaseUrl
const BACKEND_BASE_URL = process.env.REACT_APP_BASE_URL


export const PROFILE_IMAGE_URL = `${BACKEND_BASE_URL}/`

export const LOGIN = `${BACKEND_BASE_URL}/api/user/login`

// Dashboard
export const GET_DASHBOARD_COUNTRS = `${BACKEND_BASE_URL}/api/dashboard/counters`
export const GET_GST_DATA = `${BACKEND_BASE_URL}/api/dashboard/gst_gst_data`
export const GET_ESTIMATE_DATA = `${BACKEND_BASE_URL}/api/dashboard/estimate_dash_data`
export const GET_INCOME_DATA = `${BACKEND_BASE_URL}/api/dashboard/receipt_dash_data`
export const GET_EXPENSE_DATA = `${BACKEND_BASE_URL}/api/dashboard/expense_dash_data`

// Vendors
export const ADD_PARTNER = `${BACKEND_BASE_URL}/api/partners/add_partner`
export const GET_VENDOR = `${BACKEND_BASE_URL}/api/partners/get_vendor`
export const GET_PARTNER = `${BACKEND_BASE_URL}/api/partners/get_partners`
export const GET_PARTNER_LIST = `${BACKEND_BASE_URL}/api/partners/get_partners_list`
export const GET_SINGLE_PARTNER = `${BACKEND_BASE_URL}/api/partners/get_single_partners`
export const UPDATE_PARTNER = `${BACKEND_BASE_URL}/api/partners/update_partner`

// Client Report
export const GET_CLIENT_ACCOUNT_SUMMARY = `${BACKEND_BASE_URL}/api/partners/account_summary`

// Items
export const NEW_ITEM = `${BACKEND_BASE_URL}/api/item/add_item`
export const GET_SINGLE_ITEM = `${BACKEND_BASE_URL}/api/item/get_single_item`
export const GET_ITEMS = `${BACKEND_BASE_URL}/api/item/get_item`
export const GET_ALL_ITEMS = `${BACKEND_BASE_URL}/api/item/get_all_items`
export const UPDATE_ITEMS = `${BACKEND_BASE_URL}/api/item/update_item`

// Estimates
export const SAVE_ESTIMATE = `${BACKEND_BASE_URL}/api/estimate/add_estimate`
export const UPDATE_ESTIMATE = `${BACKEND_BASE_URL}/api/estimate/update_estimate`
export const GET_ESTIMATES = `${BACKEND_BASE_URL}/api/estimate/get_estimates`
export const GET_ESTIMATES_VIEW = `${BACKEND_BASE_URL}/api/estimate/get_estimates_view`
export const GET_ESTIMATE_INVOICE_DATA = `${BACKEND_BASE_URL}/api/estimate/get_estimateData`
export const GET_ESTIMATE_INVOICE_DATA_PAGE = `${BACKEND_BASE_URL}/api/estimate/get_estimateDataPage`
export const GET_ESTIMATE_CLIENT_VIEW_INVOICE = `${BACKEND_BASE_URL}/api/estimate/get_client_estimate_invoice`
export const GET_ESTIMATE_REPORT = `${BACKEND_BASE_URL}/api/estimate/estimate_report`


// GST Invoices
export const NEW_GST_INVOICE = `${BACKEND_BASE_URL}/api/billing/new_gst_invoice`
export const UPDATE_GST_INVOICE = `${BACKEND_BASE_URL}/api/billing/update_gst_invoice`
export const GET_GST_INVOICE_DATA = `${BACKEND_BASE_URL}/api/billing/get_gst_invoice_data`
export const GET_GST_INVOICE = `${BACKEND_BASE_URL}/api/billing/get_gst_invoice`
export const GET_GST_CLIENT_VIEW_INVOICE = `${BACKEND_BASE_URL}/api/billing/get_client_gst_invoice`
export const GET_GST_REPORT = `${BACKEND_BASE_URL}/api/billing/gst_report`
export const GET_GST_CLIENT_FILTER = `${BACKEND_BASE_URL}/api/billing/gst_client_filter_report`


export const ADD_HSN = `${BACKEND_BASE_URL}/api/hsn/new_hsn`


// Receipts
export const NEW_RECEIPT = `${BACKEND_BASE_URL}/api/receipt/new_receipt`
export const GET_CLIENT_RECEIPT = `${BACKEND_BASE_URL}/api/receipt/client-receipts`

// Expense
export const NEW_EXPENSE = `${BACKEND_BASE_URL}/api/expense/new_expense`
export const GET_EXPENSE = `${BACKEND_BASE_URL}/api/expense/get_expense`
export const GET_EXPENSE_REPORT = `${BACKEND_BASE_URL}/api/expense/get_expense_report`

// Returns
export const NEW_ESTIMATE_RETURN = `${BACKEND_BASE_URL}/api/estimateReturn/Estimate_Return`
export const View_ESTIMATE_RETURN = `${BACKEND_BASE_URL}/api/estimateReturn/View_Estimate_Return`
export const View_SINGLE_ESTIMATE_RETURN = `${BACKEND_BASE_URL}/api/estimateReturn/View_SingleEstimate_Return`
