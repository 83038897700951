import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Ripples from 'react-ripples'
import { TbReportAnalytics } from 'react-icons/tb'
import { FaFileInvoiceDollar, FaPeopleArrows } from 'react-icons/fa'
// import { BsFillCartCheckFill } from 'react-icons/bs'
import { RiDashboardFill } from 'react-icons/ri'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'
import { GiBoxUnpacking } from 'react-icons/gi'
import { HiDocumentText } from 'react-icons/hi'
import { TbCoinRupee } from 'react-icons/tb'
import { Tooltip } from '@mui/material'

function Sidebar() {

    const [menuCollapse, setMenuCollapse] = useState(false)

    const toggleMenu = () => {
        setMenuCollapse(!menuCollapse)
        localStorage.setItem('menuState', menuCollapse)
    }

    return (
        <aside className={menuCollapse ? 'menu-item-collapse' : 'menu-item-expand'}>
            <div className='hamburgerConainer'>
                {!menuCollapse ? <AiOutlineMenuFold onClick={() => toggleMenu()} /> : <AiOutlineMenuUnfold onClick={() => toggleMenu()} />}
            </div>
            <ul>
                <Tooltip title="Dashboard" placement="right">
                    <li>
                        <Ripples className="side-menu-item">
                            <NavLink to='/' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <RiDashboardFill className='side-menu-icons' />
                                {!menuCollapse ? (<div>Dashboard</div>) : ""}
                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                {/* <li >
                    <Ripples className="side-menu-item">
                        <NavLink to='/Vendors' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                            <FaPeopleCarry className='side-menu-icons' />
                            {!menuCollapse ? (<div>Vendors</div>) : ""}
                        </NavLink>
                    </Ripples>
                </li> */}
                <Tooltip title="Clients" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Clients' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <FaPeopleArrows className='side-menu-icons' />
                                {!menuCollapse ? (<div>Clients</div>) : ""}

                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                <Tooltip title="Items" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Items' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <GiBoxUnpacking className='side-menu-icons' />
                                {!menuCollapse ? (<div>Items</div>) : ""}

                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                {/* <li >
                    <Ripples className="side-menu-item">
                        <NavLink to='/Purchase' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                            <BsFillCartCheckFill className='side-menu-icons' />
                            {!menuCollapse ? (<div>Purchase</div>) : ""}

                        </NavLink>
                    </Ripples>
                </li> */}
                <Tooltip title="Estimates" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Estimate' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <HiDocumentText className='side-menu-icons' />
                                {!menuCollapse ? (<div>Estimate</div>) : ""}
                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                <Tooltip title="Billing" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Billing' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <FaFileInvoiceDollar className='side-menu-icons' />
                                {!menuCollapse ? (<div>Billing</div>) : ""}

                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                <Tooltip title="Billing" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Sales_Return' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <FaFileInvoiceDollar className='side-menu-icons' />
                                {!menuCollapse ? (<div>Sales Return</div>) : ""}

                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                {/* <li >
                    <Ripples className="side-menu-item">
                        <NavLink to='/Receipt' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                            <HiCurrencyRupee className='side-menu-icons' />
                            {!menuCollapse ? (<div>Receipt</div>) : ""}

                        </NavLink>
                    </Ripples>
                </li> */}
                <Tooltip title="Expenses" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Expense' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <TbCoinRupee className='side-menu-icons' />
                                {!menuCollapse ? (<div>Expenses</div>) : ""}

                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
                <Tooltip title="Reports" placement="right">
                    <li >
                        <Ripples className="side-menu-item">
                            <NavLink to='/Reports' className={({ isActive }) => isActive ? "active-side-menu-item" : "inactive-side-menu-item"}>
                                <TbReportAnalytics className='side-menu-icons' />

                                {!menuCollapse ? (<div>Reports</div>) : ""}
                            </NavLink>
                        </Ripples>
                    </li>
                </Tooltip>
            </ul>
        </aside >
    )
}

export default Sidebar