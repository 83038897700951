import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { GET_GST_REPORT } from '../../Const/ApiConst'
import moment from 'moment'
import { instance } from '../../Const/ApiHeader'
import { utils, writeFile } from 'xlsx';


function GSTComponent() {
    const [estimateData, setEstimateData] = useState([])
    const [fromDate, setFromDate] = useState()
    const [toDate, setTodate] = useState()
    const [estimateExcelData, setestimateExcelData] = useState([])

    const [grandTTOOTTAALL, setGrandTTOOTTAALL] = useState(0)

    const loadData = async () => {

        var xdata = []
        var xtaxableAmount = 0;
        var xtaxAmount = 0;
        var xsubTotal = 0;
        var xdiscount = 0;
        var xgrandTotal = 0;
        var xsgst = 0;


        if (fromDate === undefined || toDate === undefined) {

        } else {

            try {
                const data = {
                    fromDate: fromDate,
                    toDate: toDate,
                }
                await instance.post(
                    GET_GST_REPORT,
                    data
                ).then(res => {
                    setEstimateData(res.data.data)
                    for (let index = 0; index < res.data.data.length; index++) {
                        let total_taxable_amount = 0;
                        let total_tax_amount = 0;
                        for (let ix = 0; ix < res.data.data[index].items.length; ix++) {
                            total_tax_amount = total_tax_amount + ((res.data.data[index].items[ix].rate * res.data.data[index].items[ix].qty) * res.data.data[index].items[ix].tax) / 100
                            total_taxable_amount = total_taxable_amount + (res.data.data[index].items[ix].rate * res.data.data[index].items[ix].qty)
                        }

                        xtaxableAmount = xtaxableAmount + total_taxable_amount;
                        xtaxAmount = xtaxAmount + total_tax_amount;
                        xsubTotal = xsubTotal + res.data.data[index].invoiceTotal;
                        xdiscount = xdiscount + res.data.data[index].discount;
                        xgrandTotal = xgrandTotal + res.data.data[index].invoiceTotal + res.data.data[index].transporting_charge - res.data.data[index].discount;
                        xsgst = xsgst + total_tax_amount / 2

                        xdata.push({
                            invoice_date: moment(res.data.data[index].invoice_date).format("DD-MM-YYYY"),
                            invoice_no: res.data.data[index].invoice_no,
                            clientname: res.data.data[index].partner[0].PartnerName,
                            GSTNo: res.data.data[index].partner[0].GSTNo,
                            Address: res.data.data[index].partner[0].Address,

                            // Calulate Sum of Items
                            total_taxable_amount: total_taxable_amount,
                            total_tax_amount: total_tax_amount,
                            // Calulate Sum of Items

                            SubTotal: res.data.data[index].invoiceTotal,
                            discount: res.data.data[index].discount,
                            // transporting_charge: res.data.data[index].transporting_charge,
                            invoiceTotal: res.data.data[index].invoiceTotal + res.data.data[index].transporting_charge - res.data.data[index].discount,

                            // GST Splitup
                            sgstPercent: '9%',
                            gstValue: total_tax_amount / 2,
                            cgstPercent: '9%',
                            cstValue: total_tax_amount / 2,
                            total_tax_perc: '18%',
                            total_tax_amountc: total_tax_amount,
                            narration: res.data.data[index].narration,
                            payment_received: res.data.data[index].payment_received
                        })
                    }




                    xdata.push({
                        invoice_date: "Grand Total",
                        total_taxable_amount: xtaxableAmount,
                        total_tax_amount: xtaxAmount,
                        SubTotal: xsubTotal,
                        discount: xdiscount,
                        invoiceTotal: xgrandTotal,
                        gstValue: xsgst,
                        cstValue: xsgst,
                        total_tax_amountc: xtaxAmount,
                    })


                    setGrandTTOOTTAALL(xtaxAmount)

                    setestimateExcelData(xdata)

                }).catch(err => console.log(err))

            } catch (error) {
                console.log(error);
            }
        }
    }

    const tableData = () => {
        return estimateData.map((res, i) => {
            return <tr key={i}>
                <td>{moment(res.invoice_date).format("DD/MM/YYYY")}</td>
                <td>{res.partner[0].PartnerName}</td>
                <td>{res.invoice_no}</td>
                <td className='text-end'>{res.discount.toFixed(2)}</td>
                <td className='text-end'>{res.transporting_charge.toFixed(2)}</td>
                <td className='text-end'>{(parseFloat(res.invoiceTotal) - parseFloat(res.discount) + parseFloat(res.transporting_charge)).toFixed(2)}</td>
                <td className='text-end'>{parseFloat(res.payment_received).toFixed(2)}</td>
            </tr>
        })
    }


    const handleExport = () => {

        console.log(estimateExcelData);
        const headings = [[
            'Date',
            'Invoice No',
            'Client Name',
            'GST No',
            'Address',
            'Taxable Amount',
            'GST',
            'Sub Total',
            'Discount',
            // 'Transporting Charge',
            'Grand Total',

            'SGST %',
            'SGST Value',
            'CGST %',
            'CGST Value',
            'Total Tax %',
            'Total Tax',

            'Narration',
            'Payment Collected',
        ]];


        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, estimateExcelData, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'GST Report.xlsx');

    }



    return (
        <div>
            <div className='d-flex gap-3'>
                <div>
                    <div>From Date</div>
                    <Form.Control type='date' onChange={(e) => setFromDate(e.target.value)} />
                </div>
                <div>
                    <div>To Date</div>
                    <Form.Control type='date' onChange={(e) => setTodate(e.target.value)} />
                </div>
                <div>
                    <div>&nbsp;</div>
                    <Button variant='contained' onClick={() => loadData()}>Search</Button>
                </div>
            </div>
            <div className='mt-2'>
                <div className='d-flex justify-content-end'>
                    <Button variant='contained' onClick={() => handleExport()}>Download</Button>
                </div>
                <div className='col-4'>
                    <div className='d-flex gap-2 btn btn-primary'>
                        <div>Grand Total: </div>
                        <strong>{parseFloat(grandTTOOTTAALL).toFixed(1)}</strong>
                    </div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Client Name</th>
                            <th>Invoice No</th>
                            <th>Discount</th>
                            <th>Transporting Charge</th>
                            <th>Total</th>
                            <th>Payment Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData()}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default GSTComponent