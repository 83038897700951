import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Sidebar from './Components/UI/Sidebar'
import Topbar from './Components/UI/Topbar'
import Dashboard from './Pages/Dashboard/Dashboard'
import Login from './Pages/Login/Login'
import AddUser from './Pages/Vendors/AddVendor'
import Vendors from './Pages/Vendors/Vendors'
import './App.css'
import UpdateVendor from './Pages/Vendors/UpdateVendor'
import Clients from './Pages/Clients/Clients'
import AddClient from './Pages/Clients/AddClient'
import UpdateClient from './Pages/Clients/UpdateClient'
import Purchase from './Pages/Purchase/Purchase'
import NewPurchase from './Pages/Purchase/NewPurchase'
import Items from './Pages/Items/Items'
import Billing from './Pages/Billing/Billing'
import Estimate from './Pages/Estimate/Estimate'
import NewEstimate from './Pages/Estimate/NewEstimate'
import ViewInvoice from './Pages/Estimate/ViewInvoice'
import NewBilling from './Pages/Billing/NewBilling'
import ViewGSTInvoice from './Pages/Billing/ViewGSTInvoice'
import Receipts from './Pages/Receipts/Receipts'
import NewReceipt from './Pages/Receipts/NewReceipt'
import ViewClient from './Pages/Clients/ViewClient'
import Expense from './Pages/Expense/Expense'
import Reports from './Pages/Reports/Reports'
import SalesReturn from './Pages/SalesReturn/SalesReturn'
import NewSalesReturn from './Pages/SalesReturn/NewEstimateReturn'
import NewSalesReturnEntry from './Pages/SalesReturn/NewSalesReturnEntry'
import NewGSTReturn from './Pages/SalesReturn/NewGSTReturn'
import ViewSalesReturn from './Pages/SalesReturn/ViewSalesReturn'
import EditInvoice from './Pages/Billing/EditInvoice'
import UpdateEstimate from './Pages/Estimate/UpdateEstimate'

function App() {
  const token = sessionStorage.getItem('wsstfaarvav')

  if (!token) {
    return (<Login />)
  } else {
    return (
      <>
        <Topbar />
        <div className='aside-container'>
          <Sidebar />
          <div className='main-content'>
            <Routes>
              <Route path='/Login' element={<Dashboard />} />
              <Route path='/' element={<Dashboard />} />
              <Route path='/Vendors' element={<Vendors />} />
              <Route path='/Add_Vendor' element={<AddUser />} />
              <Route path='/Update_Vendor/:id' element={<UpdateVendor />} />

              <Route path='/Clients/' element={<Clients />} />
              <Route path='/Add_Client' element={<AddClient />} />
              <Route path='/View_Client/:id' element={<ViewClient />} />
              <Route path='/Update_Client/:id' element={<UpdateClient />} />

              <Route path='/Purchase' element={<Purchase />} />
              <Route path='/New_Purchase' element={<NewPurchase />} />

              <Route path='/Items' element={<Items />} />

              <Route path='/Estimate' element={<Estimate />} />
              <Route path='/New_Estimate' element={<NewEstimate />} />
              <Route path='/Update_Estimate/:id' element={<UpdateEstimate />} />

              <Route path='/View_Estimate/:id' element={<ViewInvoice />} />
              {/* <Route path='/Print_Estimate' element={<EstimatePrint />} /> */}

              <Route path='/Billing' element={<Billing />} />
              <Route path='/New_Billing' element={<NewBilling />} />
              <Route path='/View_Invoice/:id' element={<ViewGSTInvoice />} />
              <Route path='/Edit_Invoice/:id' element={<EditInvoice />} />

              <Route path='/Sales_Return' element={<SalesReturn />} />
              <Route path='/New_Return' element={<NewSalesReturn />} />
              <Route path='/New_Estimate_Return/:id' element={<NewSalesReturnEntry />} />
              <Route path='/New_GST_Return/:id' element={<NewGSTReturn />} />
              <Route path='/View_Credit_Note/:id' element={<ViewSalesReturn />} />

              <Route path='/Receipt' element={<Receipts />} />
              <Route path='/New_Receipt/:id' element={<NewReceipt />} />

              <Route path='/Expense' element={<Expense />} />

              <Route path='/Reports' element={<Reports />} />
            </Routes>
          </div>
        </div>
        {/* <Footer /> */}
      </>

    )
  }



}

export default App