import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { GET_VENDOR } from '../../Const/ApiConst'
import './Vendors.css'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'react-data-table-component';


const partnerType = "Vendor"
const token = sessionStorage.getItem('token')
const instance = axios.create({
    headers: {
        "Content-Type": 'application/json',
        'token': token,
        'PartnerType': partnerType
    }
})
function Vendors() {
    const [vendorList, setVendorList] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchKey, setSearchKey] = useState()

    const columns = [
        {
            name: 'Vendor Name',
            selector: row => row.PartnerName,
        },
        {
            name: 'Contact Person',
            selector: row => row.pointOfContact,
        },
        {
            name: 'Contact No',
            selector: row => row.ContactNo,
        },
        {
            name: 'E-Mail Id',
            selector: row => row.Email,
        },
        {
            name: 'Address',
            selector: row => row.Address,
        },
        {
            name: 'PIN Code',
            selector: row => row.PinCode,
        },
        {
            name: 'GST No',
            selector: row => row.GSTNo,
        },
        {
            name: '',
            selector: row => <button disabled>View</button>,
        },
    ];


    const loadData = async page => {
        setLoading(true);
        const data = {
            page: page,
            per_page: perPage
        }
        try {
            await instance.post(
                GET_VENDOR,
                data
            ).then(res => {
                setVendorList(res.data.data)
                setTotalRows(res.data.total)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handlePageChange = page => {
        loadData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            page: page,
            per_page: newPerPage
        }
        const response = await instance.post(
            GET_VENDOR,
            data
        )
        setVendorList(response.data.data)
        setPerPage(newPerPage);
        setLoading(false);
    };


    useEffect(() => {
        loadData(1)
        // eslint-disable-next-line
    }, [])

    const searchVendor = async page => {
        setLoading(true);
        console.log(searchKey);
        const data = {
            page: page,
            per_page: perPage,
            searchKey: searchKey
        }
        try {
            await instance.post(
                GET_VENDOR,
                data
            ).then(res => {
                setVendorList(res.data.data)
                setTotalRows(res.data.total)
                setLoading(false)
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className='page-title'>Vendors</div>
            <main>
                <Link to='/Add_Vendor'><Button variant='contained' startIcon={<AddIcon />}>Add Vendor</Button></Link>
            </main>
            <main>
                <div className='d-flex m-3'>
                    <Form.Control type="search" placeholder='Search...' onChange={(e) => setSearchKey(e.target.value)} />
                    <Button variant='contained' className='ms-2' onClick={() => searchVendor()}>Find</Button>
                </div>
                <DataTable
                    columns={columns}
                    data={vendorList}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </main>
        </>
    )
}

export default Vendors